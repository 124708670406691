.cercador-centres {
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  &:before {
    left: -100%;
  }
  &:after {
    right: -100%;
  }
}
