.form-wrapper {
  background-color: #303030;
  padding: $spacing;
  @media #{$medium-up} {
    padding: $spacing * 1.5;
  }
  @media #{$large-up} {
    padding: $spacing * 2;
  }
}
.houdiniForm {
  &:not(.filters-form) {
    @include stack;
    fieldset {
      @include stack;
    }
  }
  .form-body {
    @include stack;
  }
  fieldset {
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    > div {
      // margin-bottom: $spacing * 0.75;
      &:last-child {
        margin-bottom: 0;
      }
      .columns {
        @media screen and (max-width: 49.9375em) {
          margin-top: $spacing;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  legend {
    font-weight: bold;
    // margin-bottom: $spacing;
  }
  label {
    display: block;
    margin-bottom: $spacing * 0.25;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2.57px;
    font-size: rem-calc(12);
    &.simple-label {
      text-transform: none;
      letter-spacing: 0;
      font-weight: 300;
      font-size: rem-calc(16);
    }
    &.error {
      text-transform: none;
      letter-spacing: 0;
      font-weight: normal;
      color: #d60000;
      margin-top: $spacing * 0.25;
      font-size: rem-calc(14);
    }
    .form-wrapper & {
      color: #fff;
    }
    .info {
      text-transform: none;
      font-weight: 300;
      letter-spacing: 0;
    }
  }
  .simple-label label {
    @extend .simple-label;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  input[type="url"],
  input[type="tel"],
  input[type="number"],
  textarea,
  select {
    border-radius: 4px;
    color: #313131;
    font-weight: normal;
    // background-color: #fbfcfc;
    border: 1px solid $body-font-color;
    padding: $spacing * 0.25 $spacing * 0.75;
    width: 100%;
    height: rem-calc(52);
    box-shadow: none;
    transition: background-color 0.1s;
    &.error {
      border-color: #f00;
    }
    &:focus {
      background-color: #fefefe;
    }
  }
  textarea {
    height: $spacing * 10;
  }

  .limitHeight {
      textarea {
        height: $spacing * 5;
      }
  }


  // checkboxes & radiobuttons (https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/)
  // CAL REVISAR I SIMPLIFICAR CODI
  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
      --active: #4a4a4a;
      --active-inner: #fff;
      --focus: 3px lightskyblue;
      --border: #000;
      --border-hover: #4a4a4a;
      --background: #fff;
      --disabled: #f6f8ff;
      --disabled-inner: #e1e6f9;

      -webkit-appearance: none;
      -moz-appearance: none;

      height: $spacing;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      // cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
      top: -4px;
      &:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
          opacity var(--d-o, 0.1s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      }
      // &:disabled {
      //   --b: var(--disabled);
      //   cursor: not-allowed;
      //   opacity: 0.9;
      //   &:checked {
      //     --b: var(--disabled-inner);
      //     --bc: var(--border);
      //   }
      //   & + label {
      //     cursor: not-allowed;
      //   }
      // }
      &:hover {
        &:not(:checked) {
          &:not(:disabled) {
            --bc: var(--border-hover);
          }
        }
      }
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      width: $spacing;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    input[type="checkbox"] {
      border-radius: 2px;
      &:after {
        width: 7px;
        height: 11px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 8px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    input[type="radio"] {
      border-radius: 50%;
      &:after {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 1;
        transform: scale(var(--s, 0.7));
        top: 3px;
        left: 3px;
      }
      &:checked {
        --s: 0.5;
      }
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    margin-right: $spacing * 0.25;
  }
  p.type-label {
    margin-bottom: rem-calc(10);
  }
  legend {
    text-transform: uppercase;
    font-weight: 400;
    font-size: rem-calc(23);
    position: relative;
    letter-spacing:1px;
  }
}
input.date {
  background-image: url("img/ico-calendario.svg");
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: calc(100% - 4px) 50%;
}

// search
.search-form {
  div {
    display: flex;
  }
  input[type="search"] {
    border-radius: 8px 0 0 8px;
    border-right-width: 0;
    flex: 1 1 auto;
    height: auto;
    padding-top: $spacing * 0.75;
    padding-bottom: $spacing * 0.75;
  }
  button {
    background-color: #fff;
    border: 1px solid $body-font-color;
    border-left-width: 0;
    border-radius: 0 8px 8px 0;
    padding: 0 $spacing * 0.5;
    flex-shrink: 0;
  }

  // cercador de centres
  &--centres {
    input[type="text"] {
      border: none;
      border-radius: 4px;
      margin-right: $spacing * 0.25;
    }
    button {
      background-color: $primary-color;
      background-image: linear-gradient(to bottom, #ec423b, #d2201c);
      border: none;
      border-radius: 4px;
    }
  }
}

// filters
.filters-form {
  fieldset {
    background-color: #ededed;
    border-radius: 8px;
    padding: $spacing * 2 $spacing * 0.75 0;
    position: relative;
  }
  legend {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1rem;
    position: relative;
    top: 2rem;
  }
  button[type="button"] {
    float: right;
    margin-top: -3rem;
    padding: 0;
    border: 0;
    background: transparent url("img/bt-abrir-filtro.svg") no-repeat;
    width: rem-calc(34);
    height: rem-calc(34);
    transition: transform 0.15s ease-in-out;
    &[aria-expanded="true"] {
      transform: rotate(0.5turn);
    }
  }
  ul {
    @include undolist;
    margin-bottom: $spacing;
  }
  input[type="checkbox"] {
    border-color: #fff;
    &:checked + span {
      font-weight: 900;
    }
  }
  label.simple-label {
    font-size: rem-calc(14);
    font-weight: normal;
    span {
      float: right;
      margin-top: 0;
    }
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }
}

// documents
.js .docs-form span.info {
  display: none;
}

// forms-steps

.form-steps {
    .audible {
       display: none;
    }
    .steps ul {
        cursor: pointer;
        display: inline-block;
        font-size: 0px;
        overflow: hidden;
        border-bottom: 1px solid #d92216;
        line-height: 1;
        box-sizing: border-box;
        padding-left: 0;
        list-style: none;
        margin: 0 0 1rem 0;
        li {
            margin: 0;
            display: inline-block;
            position: relative;
            padding: rem-calc(15 10);
            vertical-align: top;
            //background-color: #fafafa;
            box-sizing: border-box;
            font-size: rem-calc(14);
            border-right: 1px solid #fff;
            &.last {
              border-right: none;
            }
            &:before {
              display: none;
            }
            &:after {
                content: '›';
                display: block;
                position: absolute;
                right: 0px;
                top: 15px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
              &:after {
                content: none;
              }
            }
             a {
                @include hover;
                background: none;
                -webkit-text-fill-color: #000;
                color: #000;
                letter-spacing: 1px;
                font-weight: normal;
            }
            &:hover,
            &:hover:after {
                //background-color: #e6f4f9;
            }
            &.done a {
                color: #d92216;
                -webkit-text-fill-color: #d92216;

            }
            &.current a {
              color: #d92216;
              -webkit-text-fill-color: #d92216;
            }

            &.disabled,
            &.disabled:after {
              //background-color: #fff;
              cursor: default;
            }

        }

    }
    p {
          font-size: rem-calc(16);
          margin-bottom: rem-calc(15);
        }
    hr {
      margin-top: rem-calc(15);
      margin-bottom: rem-calc(15);
    }
    .content {
       .title {
           display: none;
       }
    }
    .actions {
        margin-top: 1em;
        ul {
            list-style: none;
            margin: 0;
            li {
               display: inline-block;
               padding-left: 0em;
               padding-right: 1em;
                &:before {
                  display: none;
                }
                &.disabled {
                    display: none;
                }

                a {
                    border-color: #d92216;
                    display: inline-block;
                    border: 1px solid;
                    padding: 1.125rem 1.5rem;
                    text-decoration: none;
                    color: #d92216;
                    -webkit-text-fill-color: #d92216;
                    background: #fff;
                    font-size: .75rem;
                    line-height: 1.2;
                    font-weight: 900;
                    text-align: center;
                    letter-spacing: 2.57px;
                    text-transform: uppercase;
                    -webkit-transition: .15s ease-out;
                    transition: .15s ease-out;
                    -webkit-transition-property: background-color,color;
                    transition-property: background-color,color;
                    border-radius: 4px;
                    &:hover {
                      background: #d92216;
                      color: #fff;
                      -webkit-text-fill-color: #fff;
                    }
                }
            }
        }
    }
    label {
      margin-top: rem-calc(15);
    }
    .type-label {
      font-size: rem-calc(16);
      margin-top: rem-calc(15);
    }
}
.select2-selection__rendered, .select2-results__options {
  li {
    &:before {
      content: none;
    }
  }
}
