.xifres-list {
  font-size: 0;
  li {
    display: inline-block;
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    @media #{$medium-up} {
      border-right: 1px solid $body-font-color;
      padding-top: 0;
      padding-bottom: 0;
      &:last-child {
        border-right: none;
      }
    }
    &:before {
      display: none;
    }
  }
  span {
    font-size: rem-calc(66);
    font-weight: 900;
    display: block;
    line-height: 1.2;
  }
}
