.main-footer-collapsible {
  margin-top: $spacing * 2;
  border-top: 6px solid $primary-color;
}
.main-footer {
  border-top: 6px solid $primary-color;
}
.main-footer__logos {
  text-align: left;
  padding: $spacing * 1.5 0;
  hr {
    margin-bottom: $spacing * 3;
  }
  a {
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    margin: $spacing * 0.5;
  }
  a.correu {
    margin: 0;
    font-weight: bold;
    text-decoration: underline;
    &:hover  {
      text-decoration: underline;
    }
  }
  h4 {
    font-size: rem-calc(18);
    font-weight: bold;
    color: #00477f;
    text-align: left;
  }
  h5 {
    font-size: rem-calc(15);
    font-weight: bold;
    color: #00477f;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: rem-calc(15);
  }
  p {
    margin-bottom: 0;
    color: #00477f;
    font-size: rem-calc(16);
    text-align: left;
    margin-top: 5px!important;
  }
  .logos-footer {
    margin-top: rem-calc(20);
    @media #{$medium-up} {
      margin-top: rem-calc(0);
      border-left: 1px solid #25356e;
      padding-left: rem-calc(40);
    }
    ul {
      li {
        @media #{$medium-up} {
          display: inline-block;
        }
        &:before {
          content: none;
        }
        &.first {
          padding-left: 0;
        }
      }
    }
  }
}
.main-footer__container {
  color: #fff;
  background-color: $primary-color;
  text-align: center;
  padding: $spacing 0;
  a {
    @include hover;
    color: inherit;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .column > * {
    margin-top: $spacing * 0.25;
    margin-bottom: $spacing * 0.25;
  }
  ul {
    @include undolist;
    * + * {
      margin-top: $spacing * 0.5;
    }
  }
  @media #{$medium-up} {
    img {
      margin-left: 0;
      margin-right: 0;
    }
    .column {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    p.bigger {
      padding-left: $spacing * 1.5;
      padding-right: $spacing * 1.5;
      margin-left: $spacing * 1.5;
      margin-right: $spacing * 1.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    p.text-left {
      flex: 0 1 70%;
      margin-left: auto;
      margin-right: auto;
    }
    ul {
      flex-basis: 100%;
      font-size: 0;
      * + * {
        margin-top: 0;
      }
      li {
        display: inline;
        font-size: rem-calc(14);
        &:before {
          content: "-";
          display: inline;
          margin: 0 $spacing * 0.5;
          position: static;
          background: none;
        }
        &:first-child:before {
          display: none;
        }
        a {
          text-transform: uppercase;
        }
      }
    }
  }
  @media #{$large-up} {
    p.bigger {
      border: 1px solid;
      border-width: 0 1px;
    }
    p.text-left {
      flex-basis: 60ch;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
