@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url(../../fonts/Source-Sans-Pro-italic-300.woff2) format('woff2'), url(../../fonts/Source-Sans-Pro-italic-300.ttf) format('truetype'), url(../../fonts/Source-Sans-Pro-italic-300.woff) format('woff');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url(../../fonts/Source-Sans-Pro-italic-700.woff2) format('woff2'), url(../../fonts/Source-Sans-Pro-italic-700.ttf) format('truetype'), url(../../fonts/Source-Sans-Pro-italic-700.woff) format('woff');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../../fonts/Source-Sans-Pro-normal-300.woff2) format('woff2'), url(../../fonts/Source-Sans-Pro-normal-300.ttf) format('truetype'), url(../../fonts/Source-Sans-Pro-normal-300.woff) format('woff');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Source-Sans-Pro-normal-400.eot);
  src: local('Source Sans Pro'), url(../../fonts/Source-Sans-Pro-normal-400.woff2) format('woff2'), url(../../fonts/Source-Sans-Pro-normal-400.ttf) format('truetype'), url(../../fonts/Source-Sans-Pro-normal-400.svg#SourceSansPro) format('svg'), url(../../fonts/Source-Sans-Pro-normal-400.woff) format('woff'), url(../../fonts/Source-Sans-Pro-normal-400.eot?#iefix) format('embedded-opentype');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/Source-Sans-Pro-normal-700.woff2) format('woff2'), url(../../fonts/Source-Sans-Pro-normal-700.ttf) format('truetype'), url(../../fonts/Source-Sans-Pro-normal-700.woff) format('woff');
  font-display: swap;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url(../../fonts/Source-Sans-Pro-normal-900.woff2) format('woff2'), url(../../fonts/Source-Sans-Pro-normal-900.ttf) format('truetype'), url(../../fonts/Source-Sans-Pro-normal-900.woff) format('woff');
  font-display: swap;
  unicode-range: U+000-5FF;
}

