.head {
 
  // fons blanc
  &--1 {
    background-color: #fff;
    padding-top: $spacing * 0.5;
    @media #{$medium-up} {
      padding-top: $spacing;
    }
    @media #{$large-up} {
      padding-top: $spacing * 2;
    }
  }

  // fons de color
  &--2 {
    background-color: #b2374b;
    padding-top: $spacing * 0.5;
    @media #{$medium-up} {
      padding-top: $spacing;
    }
    @media #{$large-up} {
      padding-top: $spacing * 2;
    }
    h1 {
      color: #fff;
    }
  }

  // Títol centrat
  &--3 {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #b2374b;
    padding-top: $spacing * 0.5;
    //background-image: url('img/background-centres.jpg');
    background-color: rgba(0, 78, 134, 0.72);
    background-blend-mode: multiply;
    @media screen and (min-width: 50em) {
      height: 400px;
      padding-top: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      
    }
    h1 {
      font-weight: 300;
      font-size: rem-calc(30);
      margin: 0;
      text-align: center;
      font-weight: light;
      color: #fff;
      letter-spacing: 9.47px;
      margin-bottom: rem-calc(10);
      text-transform: uppercase;
      @media #{$medium-up} {
        font-size: rem-calc(50);
        margin-bottom: $spacing *2;
      }
      @media #{$large-up} {
        font-size: rem-calc(71);
      }
    }
    .serveis &, .treballa & {
      background-color: rgba(146, 184, 0, .9);
    }
    .rdi & {
      background-color: rgba(0, 135, 207, .9);
    }
  }

  // Imatge de fons
  &--4 {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #b2374b;
    padding-top: $spacing * 1.5;
    //background-image: url('img/img-part.jpg');
    @media screen and (min-width: 50em) {
      height: 400px;
      padding-top: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: #666;
    }
    &:before {
      @media screen and (min-width: 50em) {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 60%;
        //background-image: linear-gradient(to right, #000000, transparent);
        .ie9 & {
          background-color: rgba(0,0,0,.5);
          width: 100%;
        }
      }
    }
  }
 

  // Títol notícies
  &--5 {
    padding: $spacing * 2 0;
    background-color: #b2374b;
    .curso & {
        color: #fff;
        .fecha_curso {
          font-size: rem-calc(14);
        }
      }
    .categoria {
      font-size: rem-calc(18);
      font-weight: bold;
      color: #fff;
      &:after {
          content: '';
          display: block;
          width: $spacing*2;
          height: 3px;
          background-color: #fff;
          margin-top: $spacing*.75;
      }
      a {
        color: #fff;
        @include hover;
      }
    }
    h1 {
      font-weight: bold;
      font-size: rem-calc(34);
      margin-bottom: $spacing;
      text-transform: none;
      color: #fff
    }
    time {
      display: block;
      color: #fff;
      font-size: rem-calc(14);
      margin-bottom: $spacing;
      @media #{$medium-up} {
        margin-bottom: 0;
      }
      .curso & {
        display: inline-block;
      }
    }
    .btn-container {
      @media #{$medium-up} {
        text-align: right;
      }
    }
    .btn {
      margin-bottom: 0;
    }
    .row {
      @media #{$medium-up} {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  // contenidor títol
  &__container {
    border-left: 1px solid #000;
    padding: 0 0 $spacing $spacing;

    .head--2 &,
    .head--4 & {
      border-color: #fff;
      padding-bottom: 0;
      position: relative;
      min-height: rem-calc(250);
      margin-bottom: rem-calc(20);
      .btn {
          position: absolute;
          bottom: 0;
          margin-bottom: 0;
        }
    }
    @media screen and (min-width: 50em) {
      .ie9 & {
        margin-top: $spacing*2;
      }
    }
    @media #{$medium-up} {
      padding-bottom: $spacing * 1.5;
      border-left-width: 2px;
      .head--4 & {
        min-height: rem-calc(340);
        margin-bottom: 0;
      }
    }
    @media #{$large-up} {
      padding-bottom: $spacing * 2;
      border-left-width: 3px;
      .head--4 & {
        min-height: rem-calc(400);
      }
    }
  }
}
.apartat {
  color: #000;
  text-transform: uppercase;
  font-size: rem-calc(18);
  margin-bottom: 0;
  .head--2 & {
    color: #fff;
  }
  .head--4 & {
    color: #fff;
     text-shadow: 2px 2px 4px #000000;
      @media screen and (min-width: 50em) {
        text-shadow: none;
      }
  }
  @media #{$large-up} {
    font-size: rem-calc(21);
  }
  a {
    @include hover;
    .head--1 & {
      color: #000;
    }
    .head--2 &,
    .head--4 & {
      color: #fff;
    }
  }  
}
