.scrolltop {
  transition: opacity 0.2s;
  z-index: 2;
  right: $spacing;
  bottom: $spacing;
  width: $spacing * 2;
  height: $spacing * 2;
  background: $primary-color;
  background: radial-gradient($grad-start, $grad-end);
  border-radius: 50%;
  display: block;
  position: fixed;
  transition-property: opacity, transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  .js & {
    opacity: 0;
    transform: rotate(270deg) scale(0.6);
  }
  &.is-visible {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
  &:hover,
  &:focus {
    transform: scale(1.1);
  }
  img {
    margin-bottom: 0;
  }
}
