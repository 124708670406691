.docs-list {
  @include undolist;
  > * + * {
    margin-top: $spacing !important;
  }
  li {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: $spacing;
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-size: rem-calc(18);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  p {
    font-weight: normal;
    font-size: rem-calc(16);
    margin-top: $spacing * 0.5;
  }
  time {
    margin-top: 0;
    margin-bottom: $spacing * 0.5;
  }
}
