.breadcrumb {
  @media #{$medium-up} {
    margin: $spacing * 0.5 0;
    list-style: none;
    font-size: 0;
    font-weight: normal;
    margin-left: 0;
    max-height: rem-calc(55);
  }
  > * + *,
  [aria-hidden="true"] {
    margin-left: $spacing * 0.5;
    display: inline !important;
  }
  li {
    font-size: rem-calc(12);
    display: inline;
    &:last-child a {
      color: $body-font-color;
    }
  }
  a {
    @include hover;
  }
}
.breadcrumb-container {
  display: none;
  @media #{$medium-up} {
    display: block;
    background-color: #e6e8e9;
    margin-top: 0;
  }
  .usuaris & {
    background-color: #efebdc;
  }
  .serveis &, , .treballa & {
    background-color: #ebede3;
  }
  .qui-som & {
    background-color: #efebdc;
  }
  .rdi & {
    background-color: #efebdc;
  }
}