.actualitat-list {
  @include undolist;
  margin-left: -$spacing * 0.5;
  margin-right: -$spacing * 0.5;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  > li {
    margin: 0;
    margin: $spacing * 0.5;
    width: 100%;
    width: calc(100% - 1.5rem);
    border-bottom: #c9c9c9 1px solid;
    padding-bottom: $spacing;
    display: flex;
    flex-direction: column;

    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media #{$medium-up} {
      width: 50%;
      width: calc(50% - 3rem);
      margin: $spacing;
    }
    @media #{$large-up} {
      width: 33%;
      width: calc(33% - 3rem);
      margin: $spacing;
    }
  }
  h2 {
    font-weight: 900;
    font-size: rem-calc(20);
    margin-top: $spacing * 0.75;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    a {
      text-decoration: none !important;
      color: $body-font-color !important;
      background: transparent !important;
      -webkit-text-fill-color: inherit !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  img {
    order: -2;
    margin-top: $spacing * 0.5;
  }
  p {
    font-size: rem-calc(16);
    margin-top: $spacing * 0.75;
  }
  &__date {
    padding-left: $spacing * 0.75;
    // border-left: 5px solid $primary-color;
    font-size: rem-calc(22);
    font-weight: 300;
    position: relative;
    .home & {
      color: #fff;
      font-size: rem-calc(20);
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      bottom: 5px;
      width: 5px;
      background-color: $primary-color;
    }
    span {
      display: block;
      font-size: rem-calc(56);
      letter-spacing: -0.01px;
      line-height: 1;
      .home & {
        font-size: rem-calc(45);
      }
    }
  }
  &__meta {
    p {
      font-size: rem-calc(14);
      font-weight: normal;
      padding-left: $spacing * 1.125;
      position: relative;
      .home & {
        color: #fff;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1rem;
      }
      &.loc:before {
        left: 0.125em;
        @include sprite("ico-lugar");
      }
      &.time:before {
        @include sprite("ico-hora");
      }
    }
    > * {
      margin: 0;
      + * {
        margin-top: $spacing * 0.5;
      }
    }
  }
  &__metas {
    margin-top: 0;
    p.loc {
      position: relative;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: rem-calc(5);
      padding: rem-calc(10 0);
      font-size: rem-calc(14);
      font-weight: normal;
      padding-left: $spacing * 1.125;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        font-size: 1rem;
        left: 0.125em;
        @include sprite("ico-lugar");
      }
     
    }
    p.time {
      font-size: rem-calc(14);
      font-weight: normal;
      padding-left: $spacing * 1.125;
      position: relative;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: rem-calc(10);
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0px;
        font-size: 1rem;
        @include sprite("ico-hora");
      }
    }
    > * {
      margin: 0;
      + * {
        margin-top: $spacing * 0.5;
      }
    }
  }
  &__item {
    p.categoria {
      a {
        color: $secondary-color;
        text-decoration-color: $secondary-color;
      }
    }
  }
}
time {
  display: inline-block;
  font-weight: normal;
  font-size: rem-calc(16);
  order: -2;
  margin-top: 0;
}
.time-cat {
  order: -2;
}
article {
  display: flex;
  flex-direction: column;
  .actualitat-list__date {
    margin-top: 0;
  }
  .actualitat-list__meta p {
    border: 1px solid #d9d9d9;
    border-width: 1px 0;
    padding-top: $spacing * 0.5;
    padding-bottom: $spacing * 0.5;
    margin-top: 0;
    &:before {
      top: 0.75em;
    }
    &:last-child {
      border-top: 0;
    }
  }
}
