// a11y-toggle
[data-a11y-toggle]:not([aria-controls]),
[aria-hidden="true"] {
  display: none;
}
[data-a11y-toggle="lang-mobile"], .lang-container button {
    display: block !important;
  }

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.map,
.breadcrumb, .collapsible {
  [aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  &--laterals {
    overflow: visible;
    @media #{$medium-up} {
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(334deg, $grad-end, $grad-start);
      }
      &:before {
        left: -100%;
        background-image: none;
        border-left: 20px solid #000;
      }
      &:after {
        right: -100%;
      }
    }
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
iframe {
  border: 0;
  width: 100%;
  min-height: rem-calc(350);
  @media #{$medium-up} {
    min-height: rem-calc(450);
  }
}
.view-header {
  display: flex;
  flex-direction: column-reverse;
  time {
    order: unset;
  }
}
.map {
  background-color: #ddd;
  height: rem-calc(440);
}
.map-legend {
  margin-top: $spacing;
  @media #{$large-up} {
    margin-top: $spacing * 2.75;
  }
  span {
    font-size: rem-calc(14);
    font-weight: normal;
    margin-right: $spacing;
    display: inline-block;
    &:before {
      content: "";
      font-size: 1rem;
      vertical-align: middle;
      margin-right: $spacing * 0.25;
    }
    &:last-child {
      &:before {
        @include sprite("pin-adheridos");
      }
    }
  }
}
.back {
  @include hover;
  font-weight: 400;
  display: inline-block;
  margin-top: $spacing;
  @media #{$large-up} {
    font-size: rem-calc(20);
  }
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: $spacing * 0.25;
    @include sprite("bt-volver");
    font-size: 1rem;
  }
}
.text-fitxa {
  p, ul {
    font-size: 1rem;
  }
  .lead {
    font-size: 1.25rem;
  }
}
.text-fitxa-lateral {
  p {
    font-size: 1.125rem;
  }
}
.modul-btns {
  font-size: rem-calc(24);
  text-align: center;
  font-weight: 700;
  li {
    margin-bottom: rem-calc(25);
  }
  a {
    border-radius: 50px;
    padding: $spacing * 1.5;
    &:hover {
      border-radius: 35px;
    }
  }
}
.modul-dest, .modul-dest-gris {
  padding: $spacing * 1.5;
  h3 {
    text-transform: none;
    font-size: rem-calc(20);
    font-weight: 500;
    margin-bottom: rem-calc(10);
  }
  p {
    font-size: rem-calc(14);
    line-height: 22px;
    font-weight: 300;
  }
  .btn {
    margin-top: rem-calc(20);
    &:after {
      content: none;
    }
  }
}
.modul-dest-gris {
  background-image: none;
  background-color: #e9e9e9;
  h3, p, .btn {
    color: #000 !important;
  }
  .btn {
    background-color: #fff !important;
    border-color: #000;

  }
}
.modul-text p {
  font-size: rem-calc(14);
  line-height: 22px;
}
.contacto {
  margin-bottom: rem-calc(15);
  p {
    font-size: 1rem !important;
  }
}
.dest-1 {
  .web {
    margin-top: rem-calc(15);
    p {
      font-size: 1rem !important;
    }
  }
}
.servicios {
  .back {
    margin-top: rem-calc(-20);
    margin-bottom: rem-calc(20);
  }
}
#gmap {
  max-height: rem-calc(440);
}
.text-fitxa {
  h2 {
    margin-bottom: rem-calc(15);
  }
}
.circles-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    font-size: rem-calc(18);
    justify-content: center;
  }
  &__item {
    margin: 0 0 $spacing * 1.5;
    padding: 0 $column-gutter * 0.5;
    width: 100%;
    text-align: center;
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      width: 33.33%;
    }
    > a {
      @include hover;
      display: block;
      color: #000;
    }
  }
  &__title {
    font-size: rem-calc(20);
    font-weight: 900;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    a {
      text-decoration: none;
      color: #fff;
      padding: $spacing;
      margin: 0 auto $spacing * 0.75;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 50%;
      width: $spacing * 8;
      height: $spacing * 8;
      line-height: 1.3;
      @media #{$medium-up} {
        width: $spacing * 9;
        height: $spacing * 9;
      }
      &:hover,
      &:focus {
        background-color: #272727;
      }
    }
  }
  &__image {
    margin: 0 auto $spacing * 0.75;
    border-radius: 50%;
    width: $spacing * 8;
    height: $spacing * 8;
    max-width: none;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    @media #{$medium-up} {
      width: $spacing * 9;
      height: $spacing * 9;
    }
  }
  &__subtitle {
    font-size: rem-calc(18);
    font-weight: bold;
    margin-bottom: $spacing * 0.25;
    line-height: 1.2;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  &__text {
    font-size: rem-calc(14);
    font-weight: 300;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
  }
  a {
    &:hover,
    &:focus {
      img {
        opacity: 0.9;
      }
    }
  }
}
.section-divider {
  margin-bottom: rem-calc(40);
}
.text-fitxa {
  h3 {
    margin-bottom: rem-calc(5);
  }
  h2 {
    margin-bottom: rem-calc(5);
  }
  p {
    margin-bottom: rem-calc(15);
  }
  ol {
    margin-top: rem-calc(15);
    margin-left: 1rem;
    li {
      font-size: rem-calc(16);
    }
  }
  ul {
    margin-bottom: rem-calc(15);
  }
  hr {
    margin: 15px 0;
  }
}
.info-itemas {
    line-height: 1.2;
    background-color: #f2f2f2;
    padding: 1.5rem;
    border-radius: 8px;
}
.info-list {
  margin: rem-calc(20 0);
}
.btn--hist {
  @media #{$medium-up} {
    float: right;
    position: relative;
    top: -100px;
  }
}
.img-mob {
  width: 100%;
}
.dest-box {
  background-color: #efebdc;
  padding: $spacing;
  margin-top: $spacing;
  @media #{$medium-up} {
    margin-top: 0;
  }
}
.mapa {
  max-height: rem-calc(440);
  position: relative;
}
.dades-map {
  min-height: rem-calc(440);
  background-color: rgba(146, 184, 0, 0.6);
  position:absolute;
  bottom:0;
  &:before {
      content: '';
      display: block;
      height: 100%;
      width: 1000%;
      position: absolute;
      top: 0;
      left: -1000%;
      background-color: rgba(146, 184, 0, 0.6);
    }
  .box-dades-map {
    background-color: $secondary-color;
    color: #fff;
    margin: $spacing;
    padding: $spacing;
    text-align: center;
    @media #{$medium-up} {
      margin: $spacing * 2 $spacing * 3;
    }
    h2 {
      font-size: rem-calc(24);
      color: #fff;
    }
    p {
      margin-top: rem-calc(5) !important;
    }
  }
}
.centres {
  .text-centre {
    max-width: rem-calc(600);
    margin: 0 auto;
    text-align: center;
    font-size: rem-calc(18);
  }
  .bg-grey {
    background-color: #e6e8e9;
    margin-top: 0;
    .centres-list {
      margin: $spacing 0;
      @media #{$medium-up} {
        margin: $spacing * 3 0;
      }
      li {
        height: rem-calc(350);
        @media #{$medium-up} {
          height: rem-calc(500);
        }
        position: relative;
        margin: $spacing * 1.5 0;
        &:before {
          content: none;
        }
        a {
          display: block;
          position: absolute;
          bottom: 0px;
          text-decoration: none;
          transition: all .2s ease-in-out;
          width: 100%;
          @media #{$medium-up} {
            width: 50%;
            transition: all .2s ease-in-out;
            bottom: 35px;
          }
          &:hover {
            text-decoration: underline;
             @media #{$medium-up} {
              text-decoration: none;
              transform: scale(1.1);
            }
          }
          h2 {
            color: #fff;
            font-size: rem-calc(30);
            font-weight: 700;
            text-transform: none;
            line-height: rem-calc(35);
            @media #{$medium-up} {
              font-size: rem-calc(40);
              line-height: rem-calc(45);
            }
          }
          p {
            font-size: rem-calc(20);
            font-weight: 500;
            max-width: rem-calc(450);
            margin-top: rem-calc(5)!important;
            line-height: rem-calc(27);
            padding-bottom: $spacing * 1.5;
            @media #{$medium-up} {
              font-size: rem-calc(25);
            }
          }
          span {
            position: absolute;
            right: 20px;
            bottom: 15px;
          }
        }
        &:nth-child(1n) {
          //background-image: url('img/hospital-igualada.jpg');
          background-repeat: no-repeat;
          a {
            right: 0;
            background-color: $secondary-color;
            color: #fff;
            padding: $spacing * 1;
            @media #{$medium-up} {
              padding: $spacing * 1.5;
            }

          }
        }
         &:nth-child(2n) {
          //background-image: url('img/cap-igualada.jpg');
          background-repeat: no-repeat;
          background-position: right;
          a {
            left: 0;
            background-color: $tertiary-color;
            color: #fff;
            padding: $spacing * 1;
            @media #{$medium-up} {
              padding: $spacing * 1.5;
            }
          }
        }
        &:nth-child(3n) {
          //background-image: url('img/centre-salut-mental.jpg');
          background-repeat: no-repeat;
          a {
            right: 0;
            background-color: $primary-color;
            color: #fff;
            padding: $spacing * 1;
            @media #{$medium-up} {
              padding: $spacing * 1.5;
            }
          }
        }
        &:nth-child(4n) {
          //background-image: url('img/centre-sociosanitari.jpg');
          background-repeat: no-repeat;
          background-position: right;
          a {
            left: 0;
            background-color: $secondary-color;
            color: #fff;
            padding: $spacing * 1;
            @media #{$medium-up} {
              padding: $spacing * 1.5;
            }
          }
        }
      }
    }
  }
  .hor-ub {
    border: 1px solid $tertiary-color;
    padding: $spacing;
    h3 {
      color: $tertiary-color;
      font-size: rem-calc(20);
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
.cap-servei {
    text-align: center;
    margin-top: rem-calc(20);
      @media #{$medium-up} {
        margin-top: 0;
      }
    .img-cap-servei {
      border-radius: 50%;
    }
    p.carrec {
      text-transform: uppercase;
      font-size: rem-calc(15);
    }
    p.nom {
      font-size: rem-calc(16);
      font-weight: 900;
      margin-top: 0 !important;
    }
  }
.serveis {
  .bg-gr {
    background-color: #ebede3;
    margin-top: 0;
    padding: $spacing * 2;
    h2 {
      text-align: center;
    }
  }
}
.rdi {
  .bg-grey {
    background-color: #efebdc;
    margin-top: 0;
    padding: $spacing * 2;
  }
}
.vh {
  display: none;
}
.cercador {
  article {
    border-bottom: 1px solid $tertiary-color;
    padding-bottom: rem-calc(15);
    margin-bottom: rem-calc(15);
    h2 {
      margin-top: 0;
      a {
        text-decoration: none;
        &:hover  {
          text-decoration: underline;
        }
      }
    }
  }
}
table {
  border: none;
  margin-bottom: rem-calc(30);
  thead {
    background-color: #efebdc;
    tr {
      th, td {
        color: $tertiary-color;
        font-size: rem-calc(16);
      }
    }

  }
  tbody {
    tr {
      td, th {
        color: $tertiary-color;
        font-size: rem-calc(16);
        border: none;
        ul {
          font-size: rem-calc(16);
        }
      }
      th {
        font-weight: bold;
        border-right: 1px solid $tertiary-color;
        margin-left: rem-calc(15);
      }
    }
  }
}
div#goog-gt-tt {
    display:none!important;
}
iframe#gt-nvframe {
  min-height: auto;
}
.slider-galeria {
    margin-top: 20px;
    li {
        &:before {
            content: none;
        }
    }
}
.back-pad {
    padding: 30px;
}
.autocolumns-content {
    column-count: 2;
    p {
        margin-top: 0 !important;
    }
}
.card {
    background-color: #efebdc;
    padding: 30px;
    text-align: center;
    .btn {
        margin-top: 10px;
    }
}
.card-gr {
    background-color: #ebede3;
    padding: 30px;
    .btn {
        margin-top: 10px;
    }
}

.dadespersonals{
    display: none;
}

.persona{
    display: none;
}
