// mòbil
.hamburger {
  border: 1px solid;
  border-radius: 50%;
  cursor: default;
  margin-bottom: $spacing;
  position: absolute;
  z-index: 1;
  top: $spacing;
  right: $column-gutter * 0.5;

}

#menu {
    padding-top: rem-calc(110);
  @media #{$medium-up} {
    padding-top: 0;
  }
}

.navigation {
  text-align: center;
  position: relative;
  top: -95px;
  @media #{$medium-up} {
    margin-bottom: $spacing;
    float: right;
    top: 40px;
  }
  button {
    @media #{$medium-up} {
      display: none;
    }
  }
}
@media #{$medium-up} {
  #menu {
    display: block;
  }
}
