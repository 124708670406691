.banner {
    margin: 0 auto;
    display: block;
}
.landing-intro {
    color: #000;
    text-align: center;
    padding-top: 30px;
    h1 {
        text-transform: uppercase;
        max-width:700px;
        margin: 0 auto;
        line-height: 40px;
    }
    p.btn {
        border: 0;
        background-color: #F2B718;
        margin-top: 15px !important;
        color: #000 !important;
        -webkit-text-fill-color: #000 !important;
        a {
            color: #000 !important;
            text-decoration: none !important;
            -webkit-text-fill-color: #000!important;
        }
        &:hover {
            background-color: rgba(242, 183, 24, .8);
        }
    }
}
.landing {
    .text-centre {
      max-width: rem-calc(600);
      margin: 0 auto;
      text-align: center;
      font-size: rem-calc(18);
    }
    .bg-grey {
      background-color: #efebdc;
      margin-top: 0;
      .centres-list {
        margin: $spacing 0;
        @media #{$medium-up} {
          margin: $spacing * 3 0;
        }
        li {
          height: rem-calc(650);
          ul {
              li {
                  height: 0px;
              }
          }
          @media #{$medium-up} {
            height: rem-calc(500);
          }
          position: relative;
          margin: $spacing * 1.5 0;
          &:before {
            content: none;
          }
        div {
            position: absolute;
            bottom: 0px;
            text-decoration: none;
            transition: all .2s ease-in-out;
            width: 100%;
            @media #{$medium-up} {
              width: 50%;
              transition: all .2s ease-in-out;
              bottom: 60px;
            }
            h2 {
              color: #fff;
              font-size: rem-calc(25);
              font-weight: 700;
              text-transform: none;
              line-height: rem-calc(30);
              @media #{$medium-up} {
                font-size: rem-calc(30);
                line-height: rem-calc(35);
              }
            }
            p {
              font-size: rem-calc(15);
              font-weight: 500;
              margin-top: rem-calc(5)!important;
              line-height: rem-calc(20);
              //padding-bottom: $spacing * 1.5;
              @media #{$medium-up} {
                font-size: rem-calc(15);
              }
            }
            span {
              position: absolute;
              right: 20px;
              bottom: 15px;
            }
          }
          &:nth-child(1n) {
            //background-image: url('img/hospital-igualada.jpg');
            background-repeat: no-repeat;
            div {
              right: 0;
              background-color: #A4A074;
              color: #fff;
              padding: $spacing * 1;
              @media #{$medium-up} {
                padding: $spacing * 1.5;
              }
  
            }
          }
           &:nth-child(2n) {
            //background-image: url('img/cap-igualada.jpg');
            background-repeat: no-repeat;
            background-position: right;
            div {
              left: 0;
              background-color: #A4A074;
              color: #fff;
              padding: $spacing * 1;
              @media #{$medium-up} {
                padding: $spacing * 1.5;
              }
            }
          }
        }
      }
    }
    .hor-ub {
      border: 1px solid $tertiary-color;
      padding: $spacing;
      h3 {
        color: $tertiary-color;
        font-size: rem-calc(20);
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
  .section--brown {
              background-color: #A4A074;
              color: #fff;
              padding: rem-calc(60);
    h2 {
      text-align: center;
      color: #fff;
    }
    h3, h4, h5 {
      color: #fff;
    }
    p.btn {
      margin-top: 15px !important;
      color: #000 !important;
      -webkit-text-fill-color: #000 !important;
      a {
          color: #000 !important;
          text-decoration: none !important;
          -webkit-text-fill-color: #000!important;
      }
      &:hover {
          background-color: rgba(186, 185, 184, 0.1);
      }
  }
  }
  .xarxes {
    .text-lluita {
      max-width: rem-calc(400);
    }
    h2 {
      color: #000;
      font-size: rem-calc(30);
      font-weight: 300;
      line-height: 40px;
      padding-top: rem-calc(20);
      @media #{$medium-up} {
        font-size: rem-calc(50);
        line-height: 60px;
        padding-top: rem-calc(60);
        padding-left: rem-calc(50);
      }
      span {
        color: #F2B718;
        display: block;
        font-weight: 700;
        font-size: rem-calc(30);
        @media #{$medium-up} {
          font-size: rem-calc(60);
        }

      }
    }
    p {
      color: #000;
      @media #{$medium-up} {
        padding-left: rem-calc(50);
      }
    }
    ul.xarxes-lluita {
      margin-top: rem-calc(30);
      @media #{$medium-up} {
        padding-left: rem-calc(50);
      }
      li {
        display: inline-block;
        margin-right: rem-calc(20);
        height: rem-calc(60);
        width: rem-calc(60);
        padding-left: 0;
        &:before {
          content: none;
        }
        a {
          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }