.simple-grid-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  > li {
    @include stack-small;
    margin: 0;
    //margin-bottom: $spacing * 2;
    padding: 0px $spacing * 0.25;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      padding: 0 $spacing;
    }
  }

  // fila de 3
  &--3 {
    > li {
      @media #{$medium-up} {
        width: 33.33%;
      }
    }
  }

  // fila de 4
  &--4 {
    > li {
      @media #{$large-up} {
        width: 25%;
      }
    }
  }

  h2 {
    hyphens: auto;
  }

  ul {
    font-weight: normal;
    li {
      border-bottom: 1px solid #979797;
      margin-top: 0;
      //padding-top: $spacing * 0.25;
      //padding-bottom: $spacing * 0.25;
      font-size: rem-calc(16) !important;
      @media #{$xlarge-up} {
        font-size: rem-calc(18) !important;
      }
      &::before {
        top: 1em !important;
      }
    }
  }
}

// Classes pel Houdini:

// 2 columnes
.simple-grid-list-2 {
  @extend .simple-grid-list;
}
// 3 columnes
.simple-grid-list-3 {
  @extend .simple-grid-list;
  @extend .simple-grid-list--3;
}
// 4 columnes
.simple-grid-list-4 {
  @extend .simple-grid-list;
  @extend .simple-grid-list--4;
}
