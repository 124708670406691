.top-heading {
  background-color: rgba(0, 78, 134, 0.8);
  @media #{$medium-up} {
    padding: $spacing * .5 0;
    min-height: rem-calc(59);
    position: relative;
  }
  &:before {
    @media #{$large-up} {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #f8f8f8;
      z-index: -1;
    }
  }
  &:after {
    @media #{$medium-up} {
      content: "";
      display: block;
      position: absolute;
      @include scrimGradient("to bottom", #000000);
      opacity: 0.07;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
  .usuaris & {
    background-color: rgba(146, 184, 0, 0.65);
  }
  .serveis &, .treballa & {
    background-color: rgba(146, 184, 0, 0.65);
  }
  .qui-som & {
    background-color: rgba(0, 135, 207, 0.65);
  }
  .rdi & {
    background-color: rgba(0, 135, 207, 0.65);
    p.subtitol {
      color: #fff;
    }
  }
}
.page-title {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: rem-calc(25);
  letter-spacing: 1.4px;
  text-align: center;
  line-height: 1.2;
  color: #fff;
  @media #{$medium-up} {
    position: relative;
    z-index: 1;
    text-align: left;
    font-size: rem-calc(30);
    letter-spacing: 1.4px;
  }
}
.btn__areaprivada {
    color: #fff!important;
    margin-right: 15px;
    padding-top: 2px;
}
.btn__tancar {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: #fff!important;
    margin-right: 15px;
    &:hover {
        text-decoration: underline;
    }
}

.user__list {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: #fff!important;
    margin-right: 15px;
    strong {
        font-weight: 700;
    }
    li {
        display: inline-block;
        border-left: 1px solid #fff;
        margin-left: 1em;
        padding-left: 1em;
        &:first-child {
            border-left: none;
        }
    }
}
