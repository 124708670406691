.videos-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  > li {
    margin: 0;
    padding: 0;
    margin: $spacing * 0.25;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    background: linear-gradient(297deg, $grad-end, $grad-start);
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
      width: calc(50% - 0.75rem);
    }
    @media #{$medium-up} {
      margin: $spacing;
      width: calc(50% - 3rem);
    }
    &:before {
      display: none;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 0.15s ease-in-out;
    }
    &:hover:after {
      background-color: rgba(0, 0, 0, 0.125);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: $spacing;
  }
  h2 {
    font-size: rem-calc(20);
    z-index: 1;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
  }
  a {
    color: #fff;
    -webkit-text-fill-color: #fff !important;
    text-decoration: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  time {
    font-size: rem-calc(14);
    font-weight: normal;
    color: #fff;
    order: -1;
    margin-top: 0;
    margin-bottom: $spacing * 0.5;
    position: relative;
    z-index: 1;
  }
  p {
    font-size: rem-calc(16);
    margin-top: $spacing * 0.5;
    font-weight: normal;
  }
  &__img {
    position: relative;
    margin-top: 0 !important;
    &:after {
      font-size: 1rem;
      content: "";
      display: block;
      @include sprite("ico-play-2");
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &--small {
    @media #{$medium-up} {
      margin-left: -$spacing * 0.25;
      margin-right: -$spacing * 0.25;
    }
    > li {
      @media #{$medium-up} {
        margin: $spacing * 0.25;
        width: 30%;
        width: calc(33.333% - 0.75rem);
      }
    }
    .videos-list__wrapper {
      padding: $spacing * 0.5 $spacing * 0.75;
    }
    h2 {
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
      @media #{$large-up} {
        font-size: rem-calc(20);
      }
    }
  }
}
