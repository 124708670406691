.datepicker {
  padding: $spacing * 0.5;
  &.dropdown-menu {
    background-image: linear-gradient(to bottom, #ffffff, #dedede);
    min-width: 260px;
    th {
      padding: 0;
    }
  }
  table {
    width: 100%;
  }
  thead tr:first-child {
    border-bottom: $spacing * 0.5 solid transparent;
  }
  thead tr:last-child {
    display: none;
  }
  th {
    text-transform: uppercase;
    font-size: rem-calc(12);
    letter-spacing: 2.57px;
    font-weight: 900;
  }
  td {
    font-size: rem-calc(16);
    font-weight: normal;
    &.active.day,
    &.active.year,
    &:hover {
      background: transparent;
      color: $primary-color;
    }
  }
  button {
    border: 0;
    padding: 0;
  }
}
