.results-list {
  @include undolist;
  border-top: 1px solid #cecece;
  li {
    border-bottom: 1px solid #cecece;
    padding: $spacing 0;
    margin-top: 0;
    > * + * {
      margin-top: $spacing * 0.75;
    }
  }
  p {
    font-size: rem-calc(14);
    font-weight: normal;
  }
  a {
    text-decoration: none !important;
    color: $body-font-color !important;
    background: transparent !important;
    -webkit-text-fill-color: inherit !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.tag {
  font-size: rem-calc(14);
  font-weight: bold;
  border: 1px solid;
  border-width: 1px 0;
  text-transform: uppercase;
  display: inline-block;
  padding: $spacing * 0.25 $spacing * 0.5;
  margin-right: $spacing * 0.5;
}
