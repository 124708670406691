.collapsible {
  margin-bottom: $spacing;
  .despl {
    border: 1px solid #000;
    border-radius: 4px;
    margin-bottom: rem-calc(20);
    padding-left: rem-calc(23);
  }
  &__title, &__titlenotbut {
    margin: 0;
    color: $tertiary-color!important;
    margin-bottom: $spacing * 0.25;
    border-bottom: 1px solid $tertiary-color;
    margin-bottom: 0;
    font-size: rem-calc(16);
    text-transform: none;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    .serveis & {
          color: $tertiary-color!important;
          font-size: rem-calc(18);
          border-bottom: 1px solid $tertiary-color;
          text-transform: uppercase;
          font-weight: 300!important;
          letter-spacing: 1.5px;
          margin-bottom: 15px;
        }
    button, p.buttoncoll {
      all: inherit;
      border: 0;
      padding: $spacing * 0.75 0;
      margin: 0;
      width: 100%;
      background-color: transparent;
      .serveis & {
         background-color: rgba(255, 255, 255, 0.8);
         //margin-bottom: 15px;
         padding: $spacing * 0.5 0;
        }
      @media #{$medium-up} {
        padding: $spacing 0;
      }
      &:focus {
        outline: 3px solid $focus-color;
      }
      &[aria-expanded="true"] {
        border-bottom: 0;
        svg {
          transform: rotate(0.5turn);
        }
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .serveis & {
          //padding-top: 15px;
          padding-left: 25px;
          font-size: 18px;
        }
      }
      svg {
        flex: 0 0 40px;
        padding-top: 10px;
        .serveis & {
          flex: 0 0 40px;
          padding-top: 10px;
        }
      }
    }
    p.buttoncoll {
      padding-right: rem-calc(40) !important;
    }
    + div {
      margin-bottom: $spacing * 0.25;
      padding-bottom: $spacing * 0.5;
      @media #{$medium-up} {
        padding-bottom: $spacing;
      }
      p,
      ol,
      ul {
        font-size: 1rem;
      }
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
  .content {
    padding: $spacing;
    background-color: #fff;
    .serveis & {
          margin-top: rem-calc(-15);
          background-color: transparent;
      }
    
  }
}
