.pagination {
  font-size: 0;
  // margin-top: $spacing * 3;
  li {
    padding: 0;
    display: inline-block;
    font-size: rem-calc(20);
    vertical-align: middle;
    margin-top: 0 !important;
    &:before {
      display: none;
    }
  }
  a {
    text-decoration: none;
    display: block;
    color: #aaa;
    border: 1px solid $primary-color;
    width: $spacing * 1.75;
    height: $spacing * 1.75;
    border-radius: 50%;
    text-align: center;
    margin: 0 $spacing * 0.5 $spacing * 0.5;
    line-height: 2;
  }
  .current a,
  a:hover,
  a:focus {
    color: #fff;
    -webkit-text-fill-color: #fff;
    background: radial-gradient($grad-start, $grad-end) !important;
    border-color: $primary-color;
  }
  .unavailable {
    opacity: 0.5;
    a {
      // width: auto;
      background-color: transparent;
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }
  .prev,
  .next {
    text-align: center;
    a {
      background-color: transparent;
      display: block;
      justify-content: center;
      align-items: center;
      border-color: transparent;
      font-size: 14px;
      padding-top: 8px;
      color: transparent;
      &:before {
        content: "";
        display: inline-block;
      }
      &:hover {
        background: none !important;
      }
    }
  }
  .prev {
    a {
      &:before {
        @include sprite("bt-paginacio-anterior");
      }
    }
  }
  .next {
    a {
      &:before {
        @include sprite("bt-paginacio-seguent");
      }
    }
  }
}
