html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.js body {
  @media #{$large-up} {
    padding-top: rem-calc(138); // headroom
  }
}

// Stack (https://every-layout.dev/layouts/stack/)
.stack-smaller > * + * {
  margin-top: $spacing * 0.25;
}
.stack-smaller-recursive * + * {
  margin-top: $spacing * 0.25;
}
.stack-small > * + * {
  margin-top: $spacing * 0.5;
}
.stack-small-recursive * + * {
  margin-top: $spacing * 0.5;
}
.stack > * + * {
  margin-top: $spacing * 0.5;
}
.stack-recursive * + * {
  margin-top: $spacing;
}
.stack-large > * + * {
  margin-top: $spacing * 2;
}
.stack-large-recursive * + * {
  margin-top: $spacing * 2;
}
.stack-larger > * + * {
  margin-top: $spacing * 2.5;
}
.stack-larger-recursive * + * {
  margin-top: $spacing * 2.5;
}
.stack-largest > * + * {
  margin-top: $spacing * 3;
}
.stack-largest-recursive * + * {
  margin-top: $spacing * 3;
}
.stack-sect {
  margin-top: $spacing * .5;
  margin-bottom: $spacing * .5;
  @media #{$medium-up} {
    margin-top: $spacing * 2;
    margin-bottom: $spacing * 2;
  }
}

// text en columnes
.text-2-col {
  @media #{$medium-up} {
    column-count: 2;
  }
}
.text-3-col {
  @media #{$medium-up} {
    column-count: 2;
  }
  @media #{$large-up} {
    column-count: 3;
  }
}

.top-heading + .row {
  @media #{$small-only} {
    margin-top: 0;
  }
}
