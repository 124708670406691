.share-list {
  @include undolist;
  font-size: 0;
  li {
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0 !important;
    // &:first-child a {
    //   padding-left: 0;
    //   justify-content: start;
    // }
    &:last-child a {
      border-right: 0;
    }
  }
  a {
    border-right: 1px solid #d9d9d9;
    padding: $spacing * 0.5 0;
    display: block;
    height: $spacing * 2.5;
    width: $spacing * 3;
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.15s ease-out;
    @media #{$large-up} {
      width: $spacing * 4;
    }
    &:hover {
      opacity: 0.8;
    }
    &:after {
      content: none !important;
    }
  }
}
