.btn {
  display: inline-block;
  border: 1px solid;
  padding: $spacing * 0.75 $spacing;
  text-decoration: none;
  color: $body-font-color;
  -webkit-text-fill-color: $body-font-color;
  background: #fff;
  font-size: rem-calc(12);
  line-height: 1.2;
  font-weight: 900;
  text-align: center;
  letter-spacing: 2.57px;
  text-transform: uppercase;
  transition: 0.15s ease-out;
  transition-property: background-color, color;
  &:hover,
  &:focus {
    color: #fff;
    -webkit-text-fill-color: #fff;
    background: $body-font-color;
  }

  &--transp {
    background: transparent;
    border-color: #fff;
    color: #fff;
    -webkit-text-fill-color: #fff;
    .section--bg & {
      color: $body-font-color;
      -webkit-text-fill-color: $body-font-color;
      border-color: $body-font-color;
      &:hover,
      &:focus {
        color: #fff;
        -webkit-text-fill-color: #fff;
      }
    }
  }

  &--solid {
    color: #fff;
    -webkit-text-fill-color: #fff;
    background-color: $primary-color;
    background-image: linear-gradient(to bottom, #ec423b, #d2201c);
    border-color: $primary-color;
    // border-radius: 0;
    padding: $spacing * 0.5 $spacing * 1.5;
    transition: none;
    &:hover,
    &:focus {
      color: $primary-color;
      -webkit-text-fill-color: $primary-color;
      background: #fff;
    }
  }

  &--wider {
    padding-left: $spacing * 4;
    padding-right: $spacing * 4;
  }

  &--small {
    border: 1px solid $body-font-color;
    background-color: transparent;
    color: $body-font-color;
    font-size: rem-calc(14);
    font-weight: bold;
    width: 100%;
    padding: rem-calc(8) $spacing;
    @media screen and (min-width: 25em) {
      width: auto;
      padding-left: $spacing * 1.5;
      padding-right: $spacing * 1.5;
    }
    &:hover,
    &:focus {
      background: transparent;
      text-decoration: underline;
    }
  }

  &--large {
    font-size: 1rem;
    border: 2px solid $primary-color;
    color: $primary-color;
    -webkit-text-fill-color: $primary-color;
    text-indent: 3.43px;
    letter-spacing: 3.43px;
    padding: $spacing $spacing * 0.5;
    @media #{$medium-up} {
      padding-left: $spacing * 3;
      padding-right: $spacing * 3;
    }
    &:hover,
    &:focus {
      background: $primary-color;
    }
  }
  &--red {
    color: $primary-color;
    -webkit-text-fill-color: $primary-color;
    &:hover,
    &:focus {
      background: $primary-color;
    }
  }
}

// pels botons posats amb l'editor
p.btn {
  border: 1px solid #000;
  padding: 0;
  &:hover {
    background: #fff;
    border: 1px solid #000;
  }
  a {
    display: block;
    text-decoration: none !important;
    color: $body-font-color !important;
    -webkit-text-fill-color: $body-font-color !important;
    background: transparent !important;
    padding: $spacing * 0.75 $spacing;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.btn-desc {
  background-color: transparent;
  position: relative;
  margin: 0 auto;
  display: block;
  max-width: 320px;
  text-align: left;
  text-transform: uppercase;
  padding-left: rem-calc(65);
  &:before {
    content: '';
    background-image:url('img/ico-down.svg');
    width: 33px;
    height: 35px;
    position: absolute;
    left: 15px;
    top: 10px;
  }
}
.btn-xxss {
  background-color: transparent;
  position: relative;
  margin: 0 auto;
  display: block;
  max-width: 320px;
  text-align: left;
  text-transform: uppercase;
  padding-left: rem-calc(65);
  float: right;
  &:before {
    content: '';
    background-image:url('img/ico-xxss.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    left: 10px;
    top: 6px;
  }
}
.mes_xxss {
  border-top: 3px solid $primary-color;
  padding-top: rem-calc(15);
  margin-top: rem-calc(15);
}
