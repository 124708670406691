// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-weight: 300;
}
h2,
.t2 {
  font-size: rem-calc(23);
  font-weight: bold;
  color: #004e86;
  text-transform: uppercase;
  margin-bottom: $spacing * 0.5;
  @media #{$medium-up} {
    font-size: rem-calc(25);
  }
  @media #{$large-up} {
    font-size: rem-calc(25);
  }
  article & {
    font-weight: 900;
    line-height: 1.3;
    margin-top: $spacing;
    @media #{$large-up} {
      font-size: rem-calc(32);
    }
  }
  .error & {
    text-transform: uppercase;
    font-weight: 900;
    @media #{$medium-up} {
      font-size: rem-calc(60);
    }
    @media #{$large-up} {
      font-size: rem-calc(80);
    }
  }
  .view-header & {
    margin-top: $spacing;
    @media #{$large-up} {
      font-size: rem-calc(40);
      line-height: 1.15;
    }
    @media #{$xlarge-up} {
      // margin-top: $spacing * 2;
      font-size: rem-calc(44);
    }
  }
}
h3,
.t3 {
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
  color: $primary-color;
  margin-top: $spacing * 0.5;
  margin-bottom: $spacing * 0.5;
  @media #{$medium-up} {
    font-size: rem-calc(23);
  }
  @media #{$medium-up} {
    font-size: rem-calc(23);
  }
}
h4,
.t4 {
  font-size: rem-calc(20);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  margin-top: $spacing * 0.25;
  margin-bottom: $spacing * 0.25;
}
h5,
.t5 {
  color: $secondary-color;
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
}
p {
   margin-top: rem-calc(10) !important; 
}
p,
ol,
ul,
dl {
  font-size: rem-calc(16);
  line-height: 1.6;
  //margin-top: rem-calc(10) !important;
  @media #{$medium-up} {
    font-size: rem-calc(16);
  }
  @media #{$xlarge-up} {
    font-size: rem-calc(18);
  }

  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

ol,
dl,
dt,
dd,
figure,
img,
form,
hr,
table {
  margin: 0;
  padding: 0;
  // font-size: rem-calc(15);
}
.main-content {
  ul,
  ol,
  figure {
    //@include stack-small;
  }
}
ul {
  list-style: none;
  li {
    position: relative;
    break-inside: avoid;
    padding-left: $spacing * 0.5;
    &:before {
      content: "";
      background: url('img/ico-llista.svg') no-repeat;
      display: block;
      height: 8px;
      width: 5px;
      position: absolute;
      left: 0;
      top: 10px;
    }
    ul:not(.list-large) {
      font-weight: normal;
      margin-top: 0 !important;
      li {
        font-size: rem-calc(14);
        padding-left: $spacing * 0.75;
        &:before {
          height: 8px;
          width: 5px;
          top: 8px;
        }
      }
    }
  }
}

ol {
  margin-left: $spacing;
  li::marker {
    color: $tertiary-color;
    font-weight: bold;
  }
}

// llista simple, sense klanders
ul,
ol {
  &.simple-list {
    list-style: none;
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
}

// llista amb separadors
.borders-list {
  @include undolist;
  li {
    margin-top: 0;
    padding: 0 0 $spacing * 0.5 0;
    &:first-child {
      padding-top: 0;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      margin-top: $spacing * 0.5;
      background: linear-gradient(297deg, $grad-end, $grad-start);
    }
  }
  a {
    @include hover;
  }
}

// llista documents relacionats
.rel {
  @include undolist;
  font-size: rem-calc(14);
  li {
    background-color: #eeeeee;
    padding: $spacing * 0.75 $spacing;
    margin-bottom: rem-calc(5);
    .serveis & {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
  a {
    // @include hover;
    font-weight: bold;
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      font-weight: 300;
      //background-color: #979797;
      width: 1px;
      height: $spacing;
      margin-left: $spacing * 0.75;
      margin-right: $spacing * 0.5;
    }
    &[href^="http://"]::after {
      content: '';
      background: url('img/external-link.svg') no-repeat;
      background-position: 100% 50%;
      width: 24px;
      width: calc(24px + 1rem);
      height: 24px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      height: 25px;
      //right: $spacing*.5;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
    }
    &[href^="https://"]::after {
      content: '';
      background: url('img/external-link.svg') no-repeat;
      background-position: 100% 50%;
      width: 24px;
      width: calc(24px + 1rem);
      height: 24px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      height: 25px;
      //right: $spacing*.5;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
    }
    &[href$=".doc"]::after {
      content: 'Word';
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 1px 20px;
    }
     &[href$=".docx"]::after {
      content: 'Word';
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 1px 20px;
    }
    &[href$=".pdf"]::after {
      content: 'PDF';
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 1px 20px;
    }
    &.onlyLink:after {
      //display: none;
    }
  }
}

// llista gran
ul.list-large {
  @include undolist;
  font-size: rem-calc(20);
  font-weight: bold;
  border-top: 1px solid #979797;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(22);
  }
  li {
    padding: $spacing * 0.5 $spacing;
    border-bottom: 1px solid #979797;
    margin-top: 0;
    @media #{$medium-up} {
      padding: $spacing * 0.75 $spacing * 1.5;
    }
    @media #{$large-up} {
      padding: $spacing $spacing * 2;
    }
  }
}

ul.two-columns {
    @media #{$medium-up} {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
      }
    }
ul.list-telf {
  li {
    border-bottom: 1px solid #ccc;
    font-size: rem-calc(18);
    color: #004e86;
    padding-left:0;
    padding-bottom: $spacing * 0.5;
    &:before {
      content: none;
    }
    p {
      
      float: right;
      a {
        font-weight: 700;
      }
    }
  }
}
.dl-inline {
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dt {
    float: left;
    margin-right: 0.3em;
  }
}
p.dl {
  background-color: #eeeeee;
  padding: $spacing * 0.75 $spacing;
  margin-bottom: rem-calc(5);
  a {
    // @include hover;
    font-weight: bold;
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      font-weight: 300;
      //background-color: #979797;
      width: 1px;
      height: $spacing;
      margin-left: $spacing * 0.75;
      margin-right: $spacing * 0.5;
    }
    &[href^="http://"]::after {
      content: '';
      background: url('img/external-link.svg') no-repeat;
      background-position: 100% 50%;
      width: 24px;
      width: calc(24px + 1rem);
      height: 24px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      height: 25px;
      //right: $spacing*.5;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
    }
    &[href^="https://"]::after {
      content: '';
      background: url('img/external-link.svg') no-repeat;
      background-position: 100% 50%;
      width: 24px;
      width: calc(24px + 1rem);
      height: 24px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      height: 25px;
      //right: $spacing*.5;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 17px 0;
    }
    &[href$=".doc"]::after {
      content: 'Word';
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 1px 20px;
    }
     &[href$=".docx"]::after {
      content: 'Word';
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 1px 20px;
    }
    &[href$=".pdf"]::after {
      content: 'PDF';
      display: inline-block;
      font-size: 1rem;
      vertical-align: middle;
      position: absolute;
      border-left: 1px solid #000;
      margin-left: $spacing*.75;
      padding: 1px 20px;
    }
    &.onlyLink:after {
      //display: none;
    }
  }

}
figcaption {
  font-size: rem-calc(12);
}

::selection {
  // color: #fff;
  background-color: #d0d0d0;
  -webkit-text-fill-color: $body-font-color;
}
strong,
b {
  font-weight: 400;
}
// em,
// i {
// }

.upp {
  text-transform: uppercase;
}

// links underline
a {
  color: $link-color;
  .main-content &:not(.btn) {
    /*@supports (-webkit-background-clip: text) {
      background: linear-gradient(297deg, $grad-end, $grad-start);
      -webkit-background-clip: text;
    }*/
    //-webkit-text-fill-color: transparent;
    //-webkit-box-decoration-break: clone;
    text-decoration-color: $tertiary-color;
    font-weight: 700;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    transition-property: text-decoration-color;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
    &:hover {
      text-decoration: none;
      text-decoration-color: transparent;
    }
  }
  &.external {
    main & {
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        @include sprite("external-link");
        font-size: 1rem;
        margin-left: $spacing * 0.25;
        opacity: 0.3;
        background-color: transparent;
      }
    }
  }
}

// link destacat
p.link-dest {
  font-size: rem-calc(22);
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing $spacing * 0.5;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  @media #{$medium-up} {
    font-size: rem-calc(26);
  }
}

// focus
a,
button,
input,
select,
textarea,
[role="button"] {
  &:focus {
    outline: 3px solid $focus-color;
  }
}

.dest-1 {
  line-height: 1.2;
  background-color: #f2f2f2;
  padding: $spacing;
  border-radius: 8px;
  margin-bottom: $spacing;
  > dl {
    font-size: rem-calc(16);
  }
  dt:not(:first-child) {
    margin-top: $spacing;
  }
  dd {
    margin-top: $spacing * 0.25;
  }
  a[href*="mailto"] {
    word-wrap: break-word;
  }
}
.dest-2 {
  @include stack;
  text-align: center;
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing $spacing * 0.5;
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: $spacing !important;
  margin-bottom: $spacing;
  h2 {
    color: $primary-color;
  }
  p:not(.btn) {
    font-size: rem-calc(20);
  }
}
.dest-3 {
  padding: $spacing;
  text-align: center;
  border-radius: 8px;
  background-image: linear-gradient(291deg, $grad-end, $grad-start);
  font-weight: bold;
  color: #fff;
  a {
    text-decoration: none !important;
    color: #fff !important;
    background: transparent !important;
    -webkit-text-fill-color: inherit !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
.dest-4 {
  border: solid #979797;
  border-width: 1px 0;
  padding: $spacing * 0.5 0;
  @media #{$large-up} {
    font-size: rem-calc(18);
  }
  a {
    @include hover;
    color: $primary-color;
    -webkit-text-fill-color: $primary-color !important;
    &:hover {
      text-decoration-color: $primary-color !important;
    }
  }
}
p.dest,
.dest p {
  @extend .dest-1;
}

hr {
  border: 0;
  border-top: 1px solid #d9d9d9;
  // margin: $spacing * 2 0 !important;
}
table {
  width: 100%;
  font-size: rem-calc(16);
  font-weight: normal;
  th,
  td {
    vertical-align: baseline;
  }
  thead {
    th {
      color: $body-font-color;
      font-weight: bold;
      text-align: left;
      padding: 0 $spacing * 0.5 $spacing * 0.5;
      border-bottom: 1px solid $secondary-color;
      line-height: 1.2;
    }
  }
  tbody {
    td,
    th {
      padding: $spacing * 0.75 $spacing * 0.5;
      text-align: left;
      border-bottom: 1px solid #d9d9d9;
    }
    th {
      font-weight: bold;
    }
  }
}
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}
.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.inline-list {
  @include undolist;
  font-size: 0;
  margin-left: -$spacing;
  margin-right: -$spacing;
  text-align: center;
  li {
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin: $spacing $spacing 0;
  }
}

// tipus de paràgrafs
p.lead {
  font-weight: bold;
}
.intro {
  strong {
    font-weight: bold;
    position: relative;
    display: inline-block;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      margin-top: 1px;
      background-image: linear-gradient(273deg, $grad-end, $grad-start);
      @media #{$medium-up} {
        height: 4px;
        margin-top: 2px;
      }
      @media #{$large-up} {
        height: 6px;
        margin-top: 3px;
      }
    }
  }
}
.results {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  strong {
    color: $tertiary-color;
    font-weight: inherit;
    font-size: rem-calc(20);
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(30);
    }
  }
}

dt {
  font-weight: bold;
}
.info {
  font-weight: normal;
  font-size: rem-calc(14);
  text-transform: uppercase;
  a {
    display: block;
  }
}
