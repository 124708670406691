.section {
  padding-top: $spacing;
  padding-bottom: $spacing;
  @media #{$medium-up} {
    padding-top: $spacing;
    padding-bottom: $spacing;
    .home & {
      padding-top: $spacing * 1.5;
      padding-bottom: $spacing * 1.5;
    }
  }
  @media #{$large-up} {
    padding-top: $spacing;
    padding-bottom: $spacing * 1.5;
    .home & {
      padding-top: $spacing * 2;
      padding-bottom: $spacing * 2;
    }
  }
  &--grad {
    background-color: #00477f;
    background-image: linear-gradient(to bottom, #0087cf, #00477f);
    h2 {
      color: #fff;
      text-indent: 4.71px;
      letter-spacing: 4.71px;
      @media #{$medium-up} {
        font-size: rem-calc(22);
      }
    }
  }
  &--bg {
    background-color: #efebdc;
  }
  &--bg-grey {
    background-color: #e6e8e9;
  }
  &--bg-gr {
    background-color: #ebede3;
  }
  &--bg-green {
    background-color: $secondary-color;
  }
  &--bg-white {
    .qui-som & {
      text-align: center;
    }
  }
  &--bg-blue {
    background-color: $primary-color;
    color: #fff;
    ul {
      li {
        &:before {
          content: '';
            background: url('img/ico-llista-bl.svg') no-repeat;
            display: block;
            height: 8px;
            width: 5px;
            position: absolute;
            left: 0;
            top: 10px;
        }
      }
    }
     h2 {
      color: #fff;
    }
    h3 {
      color: #fff;
      font-size: rem-calc(23);
    }
  }
  &--bg-img {
    //background-image: url('img/background-servei.jpg');
    background-color: rgba(51, 51, 51, .7);
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    h2 {
      color: #fff;
    }
    h3 {
      color: $secondary-color;
      font-size: rem-calc(23);
    }
    ul {
      li {
        &:before {
          content: '';
            background: url('img/ico-llista-bl.svg') no-repeat;
            display: block;
            height: 8px;
            width: 5px;
            position: absolute;
            left: 0;
            top: 10px;
        }
      }
    }
    .qui-som & {
      //background-image: url('img/img-historia.jpg');
      h3 {
        font-size: rem-calc(20);
        color: #fff;
      }
      .text {
        @media #{$medium-up} {
          width: 65%;
        }
        .btn {
          margin-top: rem-calc(20);
        }
      }
    }
  }
}
.destacat {
  color: #fff;
  text-align: center;
  h2 {
    max-width: rem-calc(450);
    margin: 0 auto;
    color: #fff;
    text-transform: none;
    font-size: rem-calc(22);
  }
  ul {
    li {
      &:before {
        content: none;
      }
      div {
        text-align: left;
        border: 2px solid #fff;
        padding: $spacing;
        min-height: rem-calc(330);
        h3 {
          color: #fff;
          font-size: rem-calc(20);
        }
      }
    }
  }
}
.qui-som {
  .bg-img-right {
    background-repeat: no-repeat;
    //background-image: url('img/img-cap-igualada.jpg');
    background-position: right center;
    .text-op {
      background-color: #e6e8e9;
      padding: $spacing;
      @media #{$large-up} {
        padding: 0;
      }
    }
  }
  .bg-img-left {
    background-repeat: no-repeat;
    //background-image: url('img/img-centre-atencio.jpg');
    background-position: left center;
    .text-op {
      background-color: #ebede3;
      padding: $spacing;
      @media #{$large-up} {
        padding: 0;
      }
    }
  }
}