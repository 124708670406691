.main-nav {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.4px;
  font-size: 0;
  @include undolist;
  @media #{$medium-up} {
    text-align: left;
  }
  > * + * {
    margin-top: $spacing * 0.75;
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  > li {
    // &.active a,
    // a[aria-current="page"] {
    // }
    font-size: rem-calc(16);
    @media #{$medium-up} {
      display: inline;
      margin-left: rem-calc(10);
      font-size: rem-calc(14);
    }
    @media #{$large-up} {
      margin-left: rem-calc(10);
      font-size: rem-calc(15);
    }
    @media #{$xlarge-up} {
      margin-left: rem-calc(25);
      font-size: rem-calc(16);
    }
    > a {
      text-decoration: none;
      color: #004e86;
      display: block;
      @media #{$medium-up} {
        display: inline;
        border-bottom: 2px solid transparent;
        border-top: 2px solid transparent;
        transition: border-bottom-color 0.15s ease-in-out;
        padding: $spacing * .3 0;
      }
      &:hover,
      &[aria-current="page"] {
        @media #{$medium-up} {
          border-bottom-color: $tertiary-color;
          border-top-color: $tertiary-color;
        }
      }
    }
    &.first {
      @media #{$medium-up} {
        margin-left: 0;
      }
    }
  }
}
