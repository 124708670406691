.logos-landing {
  /*h5 {
    display: none;
  }*/
  h5 {
    padding-bottom: 1.5rem;
  }
  li {
    padding-left: 0;
    padding-bottom: .75rem;
    @media #{$medium-up} {
      display: inline-block;
      padding-left: 1.75rem;
    }
    &:before {
      content: none;
    }
    &.first {
      padding-left: 0;
    }
  }
}