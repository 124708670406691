.sub-nav {
  @include undolist;
  font-weight: bold;
  font-size: rem-calc(14);
  margin-bottom: $spacing * 1.5;
  background-color: #e6e8e9;
  padding: $spacing;
  @media #{$medium-up} {
    font-size: rem-calc(16);
    position: relative;
    z-index: 1;
    margin-right: rem-calc(20);
  }
  @media #{$large-up} {
    font-size: rem-calc(16);
  }
  > * + * {
    margin-top: 1px;
  }
  a {
    display: block;
    color: $body-font-color;
    text-decoration: none;
    border-bottom: 1px solid $tertiary-color;
    padding: $spacing * 0.5 $spacing;
    text-transform: uppercase;
    font-weight: 500;
    &:hover {
    	color: #fff;
	    border-bottom-color: transparent;
	    @media #{$xlarge-up} {
	      border-radius: 0;
	      position: relative;
	    }
    }
  }

  .active  {
  	a {
	    color: #fff;
	    border-bottom-color: transparent;
	    @media #{$xlarge-up} {
	      position: relative;
	    }
	   }
	    ul {
	    	li {
	    		a {
	    		color: $body-font-color;
	    		}
	    		&:before {
  				content: none;
	  			}
	  			&:after {
	  				content: none;
	  			}
	    	}
	    }
	  
  }
  .active {
  	a {
	    background-color: #004e86;
	    &:hover {
	      background-color: #303030;
	    }
    }
    ul {
    	li {
    		a {
    			background-color: transparent;
    		}
    	}
    }
  }

  a:hover {
    background-color: #666;
  }
  .prev,
  .prev2,
  li:last-child a {
    border-bottom-color: transparent;
  }

  &--simple {
    a:hover,
    .active a {
      color: inherit;
      border-radius: 0;
      border-bottom-color: transparent;
      @media #{$xlarge-up} {
        &:before,
        &:after {
          border-radius: 0;
        }
      }
    }
    .active a {
      background-color: #f2f2f2;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    a:hover {
      background-color: #f2f2f2;
    }
  }
   ul {
  	li {
  		a {
  			padding: rem-calc(5 10);
        border-bottom: none;
  			&:hover {
  				color: #fff;
          background-color: #666;
  			}
  			&:before {
  				content: none !important;
  			}
  			&:after {
  				content: none !important;
  			}
  		}
  		&.active {
  			a {
  				color: #fff;
  				background-color: #666;
  			}
  		}
  	}
  }
}
.menu-ancores {
  background-color: $secondary-color;
  ul {
    text-align: center;
    padding: $spacing 0;
    @media #{$medium-up} {
      display: flex;
      justify-content: space-between;
      padding: $spacing * .5 0;
    }
    li {
      &:before {
        content: none;
      }
      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(20);
        padding: $spacing 0;
        @media #{$medium-up} {
          font-size: rem-calc(15);
        }
         @media #{$large-up} {
          font-size: rem-calc(16);
        }
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
