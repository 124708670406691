.tabbed {
  margin-bottom: $spacing;
}
.tab-menu {
  padding: 0;
  margin-left: 0;
  font-size: 0;
  margin-bottom: 0;
  li {
    padding: 0;
    margin: 0 $spacing * 0.5 0 0;
    display: inline-block;
    &:before {
      display: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    text-decoration: none;
    display: inline-block;
    font-size: rem-calc(14);
    font-weight: bold;
    color: $tertiary-color !important;
    background: none !important;
    background-color: rgba(190, 198, 204, 1) !important;
    padding: $spacing * 0.5 $spacing;
    -webkit-text-fill-color: $tertiary-color !important;
    text-fill-color: $tertiary-color !important;
    .pink & {
      color: #fff !important;
      background-color: rgba(174, 70, 159, 1) !important;
      -webkit-text-fill-color: #fff !important;
      text-fill-color: #fff !important;
    }
    &:hover,
    &[aria-selected] {
      outline: 0;
      background-color: rgba(190, 198, 204, 0.5) !important;
      .pink & {
        background-color: rgba(174, 70, 159, 0.2) !important;
        color: #2b2d33 !important;
        -webkit-text-fill-color: #2b2d33 !important;
        text-fill-color: #2b2d33 !important;
      }
    }
    &:focus {
      outline: 1px dotted black;
    }

  }
}

.panel {
  outline: none;
  background-color: rgba(190, 198, 204, 0.5);
  padding: $spacing;
  @media #{$medium-up} {
    padding: $spacing $spacing * 1.5;
  }
  @media #{$large-up} {
    padding: $spacing $spacing * 2;
  }
  .pink & {
    background-color: rgba(174, 70, 159, 0.2);
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $body-font-color !important;
    margin-top: 0;
    margin-bottom: $spacing * 0.5;
  }
  h2 {
    font-size: rem-calc(16);
    font-weight: bold;
    border-bottom: 1px solid #000;
    border-bottom-color: rgba(0, 0, 0, 0.125);
    padding-bottom: $spacing * 0.25;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    @media #{$large-up} {
      font-size: rem-calc(18);
    }
  }
  h3 {
    font-size: rem-calc(15);
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
    @media #{$large-up} {
      font-size: rem-calc(16);
    }
  }
  h4 {
    font-size: rem-calc(15);
    font-weight: normal;
    text-transform: uppercase;
  }
  h5 {
    font-size: rem-calc(15);
    font-weight: bold;
  }
  h6 {
    font-size: rem-calc(15);
    font-weight: normal;
  }
  p,
  ul,
  ol {
    font-size: rem-calc(15);
    margin-bottom: $spacing * 0.5;
    font-weight: 300;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 40em) {
  .tab-menu {
    li {
      display: block;
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
      margin-top: 1px;
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      display: block;
      padding: $spacing * 0.25 $spacing * 0.5;
    }
    [aria-selected] {
      outline: 0;
    }
  }
}
.tabbed {
  &--v {
    @media screen and (min-width: 40.0625em) {
      display: flex;
      .tab-menu {
        flex-basis: 25%;
        li {
          display: block;
          margin: 0 0 $spacing * 0.5 0;
        }
        a {
          display: block;
        }
      }
    }
  }
}
