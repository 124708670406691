.main-header {
    position: relative;
    margin-top: 0;
    background: #fff;
    @media #{$medium-up} {
        position: relative;
    }
}
.site-logo {
    line-height: 1;
    font-size: rem-calc(16);
    margin: $spacing auto $spacing;
    width: 70%;
    margin-left: 0;
    @media #{$medium-up} {
        margin: $spacing auto $spacing;
        margin-bottom: $spacing * 1.75;
        width: auto;
    }
}

// hack per Safari per evitar aquest problema: https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/ Seria millor trobar una altra solució però de moment això és el que hi ha.
// _::-webkit-full-page-media,
// _:future,
// :root .main-header.sticky {
//   top: 0;
// }

// headroom
@media #{$large-up} {
    .js {
        .main-header {
            position: fixed;
            z-index: 10;
            right: 0;
            left: 0;
            top: 0;
        }
        .animated {
            animation-duration: 0.5s;
            animation-fill-mode: both;
            will-change: transform, opacity;
        }
        @keyframes slideDown {
            0% {
                transform: translateY(-100%);
            }
            100% {
                transform: translateY(0);
            }
        }
        .animated.slideDown {
            animation-name: slideDown;
        }
        @keyframes slideUp {
            0% {
                transform: translateY(0);
            }
            100% {
                transform: translateY(-100%);
            }
        }
        .animated.slideUp {
            animation-name: slideUp;
        }
    }
}
.top-head {
    background-color: $primary-color;
    height: rem-calc(30);
}
.lang-container {
    @media #{$medium-up} {
        //float: left;
    }
    @media #{$medium-up} {
        float: none;
        display: inline-block;
        vertical-align: bottom;
        margin-right: $spacing * 0.5;
        position: relative;
    }
    button {
        background-color: transparent;
        font-weight: bold;
        font-size: rem-calc(12);
        padding: 0 $spacing * 0.5 0 0;
        position: relative;
        padding-top: 5px;
        letter-spacing: 1.2px;
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 13px;
            @include triangle(3px, #fff, top);
        }
    }
}
.search--hidden {
    display: none;
}
.traductor--hidden {
    display: none;
}
.traductor--info {
    background-color: $primary-color;
    position: absolute;
    top: 35px;
    right: 0;
    left: 5px;
    border-radius: 6px;
    width: 320px;
    padding: rem-calc(20);
    z-index: 2;
    text-align: center;
    @media #{$medium-up} {
        top: 30px;
        width: 400px;
        left: auto;
    }
    h2,
    p,
    a {
        color: #fff;
    }
    .title {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: rem-calc(20);
        color: #fff;
    }
    a {
        display: inline-block;
    }
    p {
        font-weight: lighter;
        margin-bottom: 0;
        margin-top: rem-calc(15);
        a {
            padding: 0;
        }
    }
    ul {
        //margin: rem-calc(15 0);
        li {
            margin-bottom: 10px;
            margin: 1.5rem 10px 0;
            @media #{$medium-up} {
                margin-bottom: 0;
            }
            a {
                font-size: rem-calc(14);
                padding: rem-calc(10);
                color: #fff;
                font-weight: bold;
                line-height: 1;
                margin: 0;
                border: solid 1px #fff;
                border-radius: 4px;
                text-decoration: none;
                @media #{$medium-up} {
                    font-size: rem-calc(16);
                }
                &:hover,
                &:focus {
                    color: $primary-color;
                    text-decoration: none;
                    background-color: #fff;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .close {
        background-color: transparent;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 14px;
        right: 14px;
        width: 16px;
        height: 15px;
        border: 0;
        &:before {
            content: "";
            display: block;
            background-image: url("img/ico-close.svg");
            width: 12px;
            height: 12px;
            position: absolute;
            top: 0;
            right: 0;
        }
        &:after {
            content: none;
        }
        &:hover:before {
            opacity: 0.85;
        }
    }
}
.traductor__btn {
    color: #fff;
    border: 0;
}
