.blocks-list {
  @include undolist;
  margin-left: -$spacing * 0.5;
  margin-right: -$spacing * 0.5;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  > li {
    margin: 0;
    padding: $spacing * 0.5;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      width: 33.33%;
      padding: $spacing;
    }
    > * + * {
      margin-top: $spacing;
    }
  }
  h2 {
    font-size: rem-calc(24);
    height: 100%;
    @media #{$medium-up} {
      font-size: rem-calc(28);
      height: auto;
    }
    @media #{$large-up} {
      font-size: rem-calc(30);
    }
    a {
      display: block;
      color: #fff;
      -webkit-text-fill-color: #fff !important;
      font-weight: 900;
      padding: $spacing;
      text-decoration: none;
      position: relative;
      border-radius: 8px;
      height: 100%;
      min-height: $spacing * 6;
      @media screen and (min-width: 30em) {
        min-height: $spacing * 6;
      }
      @media #{$large-up} {
        min-height: $spacing * 8;
        padding: $spacing * 1.5;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 0.15s ease-in-out;
        border-radius: 8px;
      }
      &:hover {
        &:after {
          background-color: rgba(0, 0, 0, 0.125);
        }
      }
      span {
        position: relative;
        z-index: 1;
      }
    }
  }

  // Caixes de color
  &--basic {
    > li h2 a {
      background: linear-gradient(297deg, $grad-end, $grad-start) !important;
    }
  }

  // Caixes amb icones
  &--icons {
    h2 a {
      position: relative;
      @media #{$large-up} {
        padding-right: $spacing * 4;
      }
      img {
        display: block;
        position: absolute;
        top: $spacing * 0.75;
        right: $spacing;
        transition: top 0.15s ease-out;
      }
      &:hover img {
        @media #{$large-up} {
          top: $spacing * 0.5;
        }
      }
      // &:before {
      //   font-size: 0.75rem;
      //   content: "";
      //   display: block !important;
      //   margin-bottom: $spacing * 0.5;
      //   position: relative;
      //   z-index: 1;
      //   @media #{$large-up} {
      //     font-size: 1rem;
      //     top: $spacing * 1.25;
      //     position: absolute;
      //     top: $spacing;
      //     right: $spacing;
      //     transition: top 0.15s ease-out;
      //   }
      // }
      // &:hover:before {
      //   @media #{$large-up} {
      //     top: $spacing * 0.75;
      //   }
      // }
    }
    // li {
    //   &:nth-child(1) a:before {
    //     @include sprite("ico-transferencias-tecnologia");
    //   }
    //   &:nth-child(2) a:before {
    //     @include sprite("ico-capacidades-id");
    //   }
    //   &:nth-child(3) a:before {
    //     @include sprite("ico-gestion-consultoria");
    //   }
    // }
  }

  // Caixes amb imatge
  &--picts {
    h2 a {
      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode: multiply;
      @media #{$large-up} {
        transition: padding-top 0.15s ease-out !important;
      }
      &:hover {
        @media #{$large-up} {
          padding-top: $spacing * 1.25;
        }
      }
    }
  }

  p {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
}
.block-list-blue {
  margin: $spacing * .5 0;
  @media #{$medium-up} {
    margin: $spacing * 2 0;
  }
  li {
    &:before {
      content: none;
    }
    a {
      display: inline-block;
      background-color: $tertiary-color;
      color: #fff;
      font-size: rem-calc(20);
      padding: $spacing;
      padding-right: rem-calc(70);
      min-height: rem-calc(153);
      font-weight: 500 !important;
      width: 100%;
      position: relative;
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: $spacing;
        right: $spacing;
        background-color: #fff;
        -webkit-mask-image: url(img/ico-plus.svg);
        mask-image: url(img/ico-plus.svg);
        opacity: .6;
        transition: all .3s ease-out;
      }
      &:hover {
        &:before {
          transform: rotate(90deg);
          opacity: 1;
        }
      }
    }
  }
  .rdi & {
    margin-bottom: $spacing;
  }
}
.block-list-bl {
  margin: $spacing * .5 0;
  @media #{$medium-up} {
    margin: $spacing * 2 0;
  }
  li {
    &:before {
      content: none;
    }
    a {
      display: inline-block;
      border-bottom: 2px solid $tertiary-color;
      color: $tertiary-color;
      font-size: rem-calc(18);
      padding-left: 0;
      padding-right: rem-calc(70);
      padding-bottom: rem-calc(15);
      font-weight: 500 !important;
      width: 100%;
      min-height: rem-calc(50);
      text-decoration: none;
      position: relative;
      @media #{$medium-up} {
        padding-top: rem-calc(40);
        font-size: rem-calc(20);
         min-height: rem-calc(121);
      }
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: $spacing;
        right: $spacing;
        background-color: $tertiary-color;
        -webkit-mask-image: url(img/ico-plus-w.svg);
        mask-image: url(img/ico-plus-w.svg);
        opacity: .6;
        transition: all .3s ease-out;
      }
      &:hover {
        &:before {
          transform: rotate(90deg);
          opacity: 1;
        }
      }
    }
    p.title-bl {
      border-bottom: 2px solid $tertiary-color;
      color: $tertiary-color;
      font-size: rem-calc(18);
      padding-left: 0;
      padding-right: rem-calc(70);
      padding-bottom: rem-calc(15);
      font-weight: 500 !important;
      min-height: rem-calc(50);
      margin-top: 0!important;
      @media #{$medium-up} {
        padding-top: rem-calc(40);
        font-size: rem-calc(20);
         min-height: rem-calc(121);
      }
    }
  }
  .rdi & {
    margin-bottom: $spacing;
  }
}
.rdi-list {
  margin: $spacing * .5 0;
  @media #{$medium-up} {
    margin: $spacing * 2 0;
  }
  li {
    &:before {
      content: none;
    }
    a {
      display: inline-block;
      background-color: rgba(0, 78, 134, .7);
      //background-image: url('img/img-docencia.jpg');
      background-blend-mode: multiply;
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;
      font-size: rem-calc(25);
      padding: $spacing * 1.5;
      line-height: rem-calc(29);
      padding-right: rem-calc(70);
      min-height: rem-calc(290);
      font-weight: 700 !important;
      width: 100%;
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      &:before {
        content: '';
        width: 30px;
        height: 32px;
        position: absolute;
        bottom: $spacing;
        right: $spacing;
        background-color: #fff;
        -webkit-mask-image: url(img/ico-plus-g.svg);
        mask-image: url(img/ico-plus-g.svg);
        opacity: .6;
        transition: all .3s ease-out;
      }
      &:hover {
        &:before {
          transform: rotate(90deg);
          opacity: 1;
        }
      }
    }
    &:nth-child(2n) {
      a {
        background-color: $secondary-color;
        //background-image: none;
      }
    }
     &:nth-child(3n) {
      a {
        background-color: $primary-color;
        //background-image: none;
      }
    }
    &:nth-child(4n) {
      a {
        background-color: rgba(0, 78, 134, .7);
      }
    }
  }
}
.box-white {
  div {
    border: 1px solid $tertiary-color;
    padding: $spacing;
    background-color: #fff;
    @media #{$medium-up} {
      min-height: rem-calc(200);
    }
    h3 {
      font-size: rem-calc(18);
      text-transform: uppercase;
      color: $tertiary-color;
    }
    p {
      display: block;
    }

  }
}
.box-transparent {
  li {
    &:before {
      content: none;
    }
  }
  div {
    border: 1px solid $tertiary-color;
    padding: $spacing;
    background-color: transparent;
    min-height: rem-calc(170);
    ul {
        li {
          font-size: rem-calc(16)!important;
          font-weight: 300!important;
          &:before {
            content: '';
            background: url('img/ico-llista.svg') no-repeat;
            display: block;
            height: 8px;
            width: 5px;
            position: absolute;
            left: 0;
            top: 10px;
          }
        }
      }
    h3 {
      font-size: rem-calc(18);
      text-transform: uppercase;
      color: $tertiary-color;
    }
    p {
      display: block;
    }

  }
  .rdi & {
    div {
      min-height: rem-calc(260);
    }
  }
}
.serveis-list {
  li {
    &:before {
      content: none;
    }
    div {
      //background-image: url('img/img-serveis.jpg');
      background-repeat: no-repeat;
      height: rem-calc(240); 
      position: relative;
      background-size: cover;
      a {
          color: #fff;
          display: block;
          text-align: center;
          text-transform: uppercase;
          font-size: rem-calc(20);
          font-weight: 400 !important;
          padding: $spacing;
          background-color: rgba(0, 78, 134, .8);
          background-blend-mode: multiply;
          position: absolute;
          bottom: 0;
          width: 100%;
          text-decoration: none;
          line-height: 22px;
          transition: height 1s linear;
          &:hover {
            height: 100%;
          }
      }
    }
    &:nth-child(2n) {
      div {
        a {
          background-color: rgba(0, 135, 207, .8);
        }
      }
    }
    &:nth-child(3n) {
      div {
        a {
          background-color: rgba(146, 184, 0, .8);
        }
      }
    }
    &:nth-child(4n) {
      div {
        a {
          background-color: rgba(0, 135, 207, .8);
        }
      }
    }
    &:nth-child(5n) {
      div {
        a {
          background-color: rgba(146, 184, 0, .8);
        }
      }
    }
    &:nth-child(6n) {
      div {
        a {
          background-color: rgba(0, 78, 134, .8);
        }
      }
    }
  }
}
.box-link {
  li {
    &:before {
      content: none;
    }
    a {
      background-image: url('img/img-residents.jpg');
      background-repeat: no-repeat;
      height: rem-calc(174); 
      position: relative;
      background-size: cover;
      background-color: rgba(0, 135, 207, .7);
      background-blend-mode: multiply;
      display: block;
      &:hover {
        background-color: rgba(0, 135, 207, 1);
      }
      h3 {
        color: #fff;
        text-decoration: none;
        font-size: rem-calc(22)!important;
        line-height: rem-calc(27);
        font-weight: 500 !important;
        position: absolute;
        max-width: rem-calc(220);
        bottom: 20px;
        left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
    &:nth-child(n) {
      a {
        background-color: rgba(0, 135, 207, .7);
        &:hover {
          background-color: rgba(0, 135, 207, 1);
        }
      }
    }
    &:nth-child(2n) {
      a {
        background-color: rgba(146, 184, 0, .7);
        &:hover {
          background-color: rgba(146, 184, 0, 1);
        }
      }
    }
    &:nth-child(3n) {
      a {
        background-color: rgba(0, 78, 134, .7);
        &:hover {
          background-color: rgba(0, 78, 134, 1);
        }
      }
    }
     &:nth-child(4n) {
      a {
        background-color: rgba(146, 184, 0, .7);
        &:hover {
          background-color: rgba(146, 184, 0, 1);
        }
      }
    }
    &:nth-child(5n) {
      a {
        background-color: rgba(0, 78, 134, .7);
        &:hover {
          background-color: rgba(0, 78, 134, 1);
        }
      }
    }
    &:nth-child(6n) {
      a {
        background-color: rgba(0, 135, 207, .7);
        &:hover {
          background-color: rgba(0, 135, 207, 1);
        }
      }
    }
  }
}
.box-dest-br {
  li {
    &:before {
      content: none;
    }
    a {
        text-decoration: none;
        text-underline: none;
        div {
          position: relative;
          span {
            position: absolute;
            bottom: 15px;
            right: 15px;
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
    div {
      background-color: #efebdc;
      padding: $spacing;
      min-height: rem-calc(210);
      h3 {
        color: $tertiary-color;
        font-size: rem-calc(20);
      }

    }
  }
}
.block-list-equip {
  li {
    text-align: center;
    &:before {
      content: none;
    }
    h3 {
      text-transform: none;
      font-size: rem-calc(18);
      color: $tertiary-color;
    } 
    p {
      font-size: rem-calc(16);
      margin-top: 0 !important;
      font-style: italic;
    }
  }
}