
.s-mobile {
  margin: 0 0 $spacing;
  clear: both;
  @media screen and (min-width: 50em) {
    display: none;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    div {
      display: flex;
    }
  }
  label,
  legend {
    @include visually-hidden;
  }
  input {
    flex: 1 1 auto;
    padding: $spacing * 0.5 0 $spacing * 0.5 $spacing * 0.5;
    border-radius: 4px 0 0 4px;
    appearance: none;
    background-color: transparent;
    border: 1px solid #ccc;
    border-right: 0;
    &:focus {
      outline: none;
      background-color: #eee;
    }
  }
  button {
    background-color: #fff;
    padding: 0 $spacing * 0.5;
    border-radius: 0 4px 4px 0;
    border: 1px solid #ccc;
    img {
      margin: 0;
    }
  }
}
.search-container {
  position: relative;
  display: none;
  @media #{$medium-up} { 
    display: block;
    padding-left: $spacing*.5;
    border-left: 1px solid #000;
    .ie9 & {
      display: inline-block; // flexbox fallback
    }
  }
  @media #{$medium-up} {
    border-left: 0;
    position: relative;
  }
  &__btn {
    background-color: transparent;
    padding: 0 $spacing*.5;
    border: none;
    &:after {
      content: '';
      //@include sprite('ico-search');
      background-image: url('img/ico-search.svg');
      background-repeat: no-repeat;
      width: 15px;
      height: 15px;
      display: block;
      top: 4px;
      position: relative;
    }
    &[aria-expanded="true"] {
      &:after {
        content: '';
        //@include sprite('ico-buscador-close');
      }
    }
    img {
      margin-bottom: 0;
    }
  }
  &__panel {
    z-index: 5;
    background-color: white;
    position: absolute;
    right: 0;
    margin-top: $spacing*.5;
  }
}
.s-desktop {
  margin: 0;
  padding: $spacing*.5;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0,0,0,.2);
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    div {
      display: flex;
      .ie9 & {
        width: 15rem; // flexbox fallback
      }
    }
  }
  legend {
    @include visually-hidden;
  }
  label {
    font-size: rem-calc(14);
    display: block;
    margin-bottom: $spacing*.25;
  }
  input {
    flex: 0 1 auto;
    padding: $spacing * 0.5 0 $spacing * 0.5 $spacing * 0.5;
    border-radius: 4px 0 0 4px;
    appearance: none;
    background-color: transparent;
    border: 1px solid #ccc;
    border-right: 0;
    width: 12rem;
    padding-top: $spacing * 0.25;
    padding-bottom: $spacing * 0.25;
    // flexbox fallback
    .ie9 & {
      width: 11rem;
      display: inline-block;
    }
    &:focus {
      outline: none;
      background-color: #eee;
    }
  }
  button {
    background-color: #fff;
    padding: 0 $spacing * 0.5;
    border-radius: 0 4px 4px 0;
    border: 1px solid #ccc;
     // flexbox fallback
    .ie9 & {
      width: 2.5rem;
      display: inline-block;
    }
    img {
      margin: 0;
        width: rem-calc(18);
        height: rem-calc(18);
        max-width: none;
    }
  }
}