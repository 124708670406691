.h-intro {
  img {
    width: rem-calc(105);
    height: rem-calc(39);
    @media #{$medium-up} {
      width: auto;
      height: auto;
    }
  }
  p {
    text-align: left;
    margin-top: 0 !important;
    @media #{$medium-up} {
      text-align: center;
    }
  }
  .box {
    position: relative;
    top: 0px;
     @media #{$large-up} {
      top: -80px;
    }
    .usuaris & {
      top: 0;
      background-color: #fff;
    }
    h2 {
      color: #fff;
      font-size: rem-calc(20);
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 2.3px;
      padding: $spacing * 0.5 $spacing;
    }
    a {
      font-size: rem-calc(18);
      text-decoration: none;
      font-weight: 300;
      &:hover {
        text-decoration: underline;
      }
    }
    ul.two-columns, ul.list-telf {
      padding: $spacing;
      padding-top: $spacing * 0.5;
    }
     ul.two-columns {
      li {
        margin-top: rem-calc(6);
      }
     }
     ul.list-telf {
      li {
        padding-top: rem-calc(10);
        &.last {
          border-bottom: none;
        }
      }
    }
  }
  .box-blue {
    border: 1px solid $primary-color;
    min-height: rem-calc(403);
    h2 {
      background-color: $primary-color;
    }
  }
  .box-green {
    border: 1px solid $secondary-color;
     min-height: rem-calc(403);
    margin-top: rem-calc(15);
    @media #{$large-up} {
      margin-top: 0;
    }
    h2 {
      background-color: $secondary-color;
    }
  }
  .box-img {
      margin-top: $spacing;
      padding: $spacing;
      @media #{$medium-up} {
        margin-top: 0;
      }
      img {
          width: 100%;
          height: auto;
          transition: transform .2s;
          opacity: 1;
          &:hover {
            transform: scale(1.1);
            opacity: 0.8;
          }
      }
  }
}
.btns-container {
  font-size: 0;
  //margin-top: $spacing;
  .btn {
    width: 100%;
    @media screen and (min-width: 40em) {
      width: auto;
      margin-left: $spacing * 0.5;
      margin-right: $spacing * 0.5;
    }
  }
}

// xifres
.xifres {
  @include undolist;
  font-size: 0;
  font-weight: 900;
  text-align: center;
  li {
    display: inline-block;
    margin: 0 $spacing $spacing;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    text-indent: 3.43px;
    letter-spacing: 3.43px;
    @media #{$large-up} {
      margin: 0 $spacing * 1.5 $spacing;
    }
  }
  span {
    color: $body-font-color;
    display: block;
    font-size: rem-calc(60);
  }
}

// notícies
ul.news {
  @include undolist;
  margin-left: -$spacing * 0.5;
  margin-right: -$spacing * 0.5;
  //display: flex;
  //flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  > li {
    margin: 0;
    padding: 0 $spacing * 0.5;
    margin-bottom: rem-calc(50);
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 40em) {

    }
    @media #{$medium-up} {
      padding: 0 $spacing;
    }
   img {
    @media #{$medium-up} {
      float: left;
      padding-right: rem-calc(28);
      display: inline-block;
    }
   }

  }
  h3 {
    text-transform: none;
    font-weight: 700;
    font-size: rem-calc(18);
    //display: inline-block;
    @media #{$medium-up} {
      font-size: rem-calc(20)!important;
    }
    a {
      text-decoration: none !important;
      color: #fff !important;
      background: transparent !important;
      -webkit-text-fill-color: #fff !important;
      font-size: rem-calc(18);
      @media #{$medium-up} {
        font-size: rem-calc(20)!important;
      }
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  time {
    font-weight: normal;
    color: #d2e32e;
    font-size: rem-calc(16);
    margin: 0;
    display: inline-block;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
  }
  p {
    font-size: rem-calc(16);
    font-weight: normal;
    color: #fff;
  }
  a {
      @include hover;
      color: #d2e32e;
      background: none;
      -webkit-text-fill-color: #d2e32e;
      font-size: rem-calc(16);
      font-weight: 700;
    }
  p.dat-home {
    margin-top: 0;
    order: 0;
    font-weight: normal;
    color: #fff;
    font-size: rem-calc(20);
    letter-spacing: 3px;
    margin: 0;
    z-index: 1;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }

  }
}

// eventos
.e-container {
  margin-top: $spacing * 2;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    margin-left: -$spacing;
    margin-right: -$spacing;
  }
  @media #{$large-up} {
    margin-top: $spacing * 3;
  }
  > * {
    // border: 1px solid;
    // flex: 1 1 33.333%;
    // // background-color: #eee;
    margin: 0;
    // padding: 0 $spacing * 0.5;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 40em) {
      width: 50%;
      padding: 0 $spacing;
    }
    @media #{$medium-up} {
      width: 33.33%;
    }
  }
  > div > .btn {
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
  }
}
.ev-dest {
  display: flex;
  flex-direction: column;
  h3 {
    text-transform: none;
    font-weight: 900;
    font-size: rem-calc(29);
    margin-top: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(26);
    }
    @media #{$large-up} {
      font-size: rem-calc(30);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(36);
    }
    a {
      text-decoration: none !important;
      color: $body-font-color !important;
      background: transparent !important;
      -webkit-text-fill-color: inherit !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  p.time {
    order: -1;
    font-size: rem-calc(26);
    font-weight: 300;
    line-height: 1.2;
    @media #{$medium-up} {
      font-size: rem-calc(30);
    }
    @media #{$large-up} {
      font-size: rem-calc(34);
    }
    time {
      display: inline;
      font-weight: inherit;
      font-size: inherit;
    }
  }
  p.loc,
  p.e-info {
    font-weight: normal;
    font-size: rem-calc(16);
    margin-top: $spacing;
    &::before {
      content: "";
      display: block;
      margin-bottom: $spacing * 0.25;
    }
  }
  p.loc {
    &::before {
      @include sprite("ico-lugar");
    }
  }
  p.e-info {
    &::before {
      @include sprite("ico-hora");
    }
    + .btn {
      margin-top: $spacing * 1.5;
      @media screen and (min-width: 40em) {
        display: none;
      }
    }
  }
}
.ev-list {
  ul {
    @include undolist;
    margin-bottom: $spacing * 1.5;
    > * + * {
      margin-top: $spacing;
    }
  }
  li {
    border-bottom: 1px solid #979797;
    padding-bottom: $spacing;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    &:before {
      content: none;
    }
    .cont-ag {
      display: inline-block;
    }
  }
  h3 {
    text-transform: none;
    font-weight: bold;
    font-size: rem-calc(20);
    margin-top: $spacing * 0.5;
    a {
      text-decoration: none !important;
      color: #fff !important;
      background: transparent !important;
      -webkit-text-fill-color: inherit !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

    /*time {
      display: inline;
      font-weight: 500;
      font-size: rem-calc(35);
      margin-top: 0;
      color: #d2e32e;
      line-height: rem-calc(20);
      @media #{$medium-up} {
        font-size: rem-calc(35);
      }
      span {
        font-size: rem-calc(18);
        display: block;
        text-transform:uppercase;
      }
    }*/
}

// cas èxit
.caso {
  display: flex;
  flex-direction: column;
  margin-left: -$column-gutter * 0.5;
  margin-right: -$column-gutter * 0.5;
  padding-bottom: 0;
  margin-top: $spacing * 2;
  width: auto;
  @media screen and (min-width: 40em) {
    margin-left: 0;
    margin-right: 0;
  }
  @media #{$medium-up} {
    width: 33.333%;
    margin-top: 0;
  }
  div {
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacing;
    background-color: rgba(0, 0, 0, 0.5);
    background-color: #942222;
    background-blend-mode: multiply;
    @media #{$medium-up} {
      padding: $spacing * 1.5;
    }
    .btn {
      display: none;
      @media #{$medium-up} {
        display: block;
        // margin-top: 0;
      }
    }
  }
  .btn.btn--transp {
    margin-top: $spacing;
  }
  h2 {
    color: #fff;
    font-weight: bold;
    letter-spacing: 4px;
    text-indent: -4px;
    font-size: 1rem;
  }
  h3 {
    text-transform: none;
    font-weight: 900;
    font-size: rem-calc(20);
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    a {
      text-decoration: none !important;
      color: #fff !important;
      background: transparent !important;
      -webkit-text-fill-color: #fff !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
  > .btn {
    margin-top: $spacing * 1.5;
  }
}

// enllaços
.links {
  @include undolist;
  font-size: 0;
  font-weight: 900;
  text-align: center;
  margin-top: $spacing * 2;
  li {
    font-size: 1rem;
    margin: 0 0 $spacing * 3;
    @media screen and (min-width: 40em) {
      display: inline-block;
      width: rem-calc(310);
      margin-left: $spacing;
      margin-right: $spacing;
    }
    @media #{$medium-up} {
      width: rem-calc(340);
    }
    @media #{$xlarge-up} {
      margin-bottom: 0;
      width: rem-calc(370);
    }
    &:nth-child(1) a:before {
      background-image: url("img/ico-cartera-servicios.png");
      background-size: cover;
      width: rem-calc(77);
      height: rem-calc(55);
      @media #{$retina} {
        background-image: url("img/ico-cartera-servicios@2x.png");
      }
      @media #{$large-up} {
        width: rem-calc(150);
        height: rem-calc(107);
      }
    }
    &:nth-child(2) a:before {
      background-image: url("img/ico-formacion.png");
      background-size: cover;
      width: rem-calc(77);
      height: rem-calc(52);
      @media #{$retina} {
        background-image: url("img/ico-formacion@2x.png");
      }
      @media #{$large-up} {
        width: rem-calc(150);
        height: rem-calc(102);
      }
    }
    &:nth-child(3) {
      margin-bottom: 0;
      a:before {
        background-image: url("img/ico-newsletter.png");
        width: rem-calc(69);
        height: rem-calc(54);
        @media #{$retina} {
          background-image: url("img/ico-newsletter@2x.png");
        }
        @media #{$large-up} {
          width: rem-calc(135);
          height: rem-calc(105);
        }
      }
    }
  }
  a {
    display: block;
    text-transform: uppercase;
    text-indent: 3.43px;
    letter-spacing: 3.43px;
    padding: $spacing * 1.25 $spacing $spacing;
    text-decoration: none !important;
    color: #fff !important;
    background: #3e3e3e !important;
    -webkit-text-fill-color: #fff !important;
    position: relative;
    @media #{$large-up} {
      padding-top: $spacing * 4;
      padding-bottom: $spacing * 2;
    }
    &:before {
      content: "";
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      transition: top 0.15s ease-in-out;
      position: absolute;
      top: -50%;
      left: 50%;
      transform: translateX(-50%);
      @media #{$large-up} {
        top: -30%;
      }
    }
    &:hover:before {
      @media #{$large-up} {
        top: -40%;
      }
    }
  }
}
.section--grad {
  h2 {
    text-align: left;
    font-size: rem-calc(30);
    letter-spacing: 2.74px;
    font-weight: 300;
    border-bottom: 1px solid #fff;
    padding-bottom: rem-calc(6);
    margin-bottom: rem-calc(25);
  }
}
.section--xarxes {
  h2 {
    margin-bottom: rem-calc(15);
  }

}
.btns-container {
  ul {
    li {
      &:before {
        content: none;
      }
      a {
        display: block;
        text-align: left;
        font-size: rem-calc(18);
        min-height: rem-calc(80);
        &:hover {
          background-color: transparent;
          color: $tertiary-color !important;
          -webkit-text-fill-color: $tertiary-color !important;
        }
        &.btn--ico {
          border: none;
          border-bottom: 1px solid $tertiary-color;
          &:after {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: rem-calc(40);
            right: $spacing;
            background-color: $tertiary-color;
            -webkit-mask-image: url(img/ico-plus-w.svg);
            mask-image: url(img/ico-plus-w.svg);
            opacity: .6;
            transition: all .3s ease-out;
          }
          &:hover {
            &:after {
              transform: rotate(90deg);
              opacity: 1;
            }
          }
        }
        &.btn-user {
          padding-left: rem-calc(60);
          &:before {
            content: '';
            background-image: url('img/ico-usuari.svg');
            background-repeat: no-repeat;
            width: 43px;
            height: 40px;
            left: 25px;
            position: absolute;
          }
        }
        &.btn-news {
          padding-left: rem-calc(60);
          padding-top: rem-calc(40);
          &:before {
            content: '';
            background-image: url('img/ico-news.svg');
            background-repeat: no-repeat;
            width: 40px;
            height: 41px;
            top: 20px;
            left: 25px;
            position: absolute;
          }
        }
        &.btn-area {
          padding-left: rem-calc(120);
          padding-top: rem-calc(30);
          background-color: $primary-color;
          color: #fff;
          -webkit-text-fill-color: #fff !important;
          &:before {
            content: '';
            background-image: url('img/ico-privat.svg');
            background-repeat: no-repeat;
            width: 25px;
            height: 32px;
            top: 20px;
            left: 100px;
            position: absolute;
          }
        }
      }
    }
  }
}
.share {
  @media #{$medium-up} {
    margin-top: rem-calc(15);
  }
  li {
    display: inline-block;
    padding-left: 0;
    padding-right: rem-calc(6);
    &:before {
      content: none;
    }
    a {
      &:hover {
        opacity: .6;
      }
    }
  }
}
.home {
  .section--grad {
    .btn--transp {
      margin-bottom: rem-calc(15);
      @media #{$medium-up} {
        margin-bottom: 0;
      }
    }
  }
}
