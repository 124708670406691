.site-tools {
  @include undolist;
  @media #{$medium-up} {
    // border: 1px solid;
    position: absolute;
    right: $column-gutter * 0.5;
    //top: $spacing;
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    justify-content: flex-end;
    align-items: center;
  }
  @media #{$large-up} {
    width: 50%;
  }
  > * + * {
    margin-top: $spacing;
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  > a {
    display: block;
    &:first-of-type {
      color: $body-font-color;
      font-weight: normal;
      @include hover;
    }
    &.btn {
      display: inline-block;
      @media #{$large-up} {
        margin-left: $spacing;
      }
    }
  }
}

.lang {
  text-transform: uppercase;
  font-size: 0;
  font-weight: normal;
  @media #{$medium-up} {
    margin-right: $spacing * 0.75;
    padding-right: $spacing * 0.5;
  }
  @media #{$large-up} {
    margin-left: $spacing * 0.75;
    padding-left: $spacing * 0.5;
    position: absolute;
    top: -1px;
    right: 30px;
  }
  li {
    font-size: rem-calc(14);
    display: inline-block;
    margin: rem-calc(5) $spacing * 0.25;
    position: relative;
    &:after {
      content: '|';
      position: absolute;
      top: 0px;
      color: #fff;
      left: -8px;
    }
    &.first {
      &:after {
        content: none;
      }
    }
  }
  a {
    color: #fff;
    display: block;
    text-align: center;
    text-decoration: none;
    letter-spacing: 1.61px;
    padding: 0 rem-calc(3);
    &:hover {
      color: $secondary-color;
    }
    &.active {
      color: $secondary-color;
    }
  }
  abbr {
    text-decoration: none;
    border-bottom: 0;
  }
}
