.gallery {
  margin-top: rem-calc(20);
  &__item {
    display: inline-block;
    //flex-direction: column-reverse;
    margin-bottom: $spacing * 2 !important;
    position: relative;
    max-width: rem-calc(250);
    &:before {
      content: none;
    }
    &:hover,
    &:focus {
      .galeria__title:before {
        opacity: 1;
      }
      .galeria__img-wrapper:before {
        opacity: 0.5;
      }
    }

    &--dest {
      @media #{$medium-up} {
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: $spacing * 3;
      }
      @media #{$large-up} {
        margin-bottom: $spacing * 4;
      }
      .galeria__img-wrapper {
        @media #{$medium-up} {
          margin-bottom: 0;
        }
      }
    }
    &--video {
      .galeria__img-wrapper {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          //@include sprite("ic_slideshow_24px");
          left: 50%;
          top: 50%;
          transform: translate(-50%) scale(2);
          opacity: 1;
          transition: opacity 0.15s ease-in-out;
        }
      }
      .galeria__title:before {
        //@include sprite("ic_slideshow_24px");
        display: block;
      }
    }
    &--fotos {
      .galeria__title:before {
        display: block;
      }
    }
  }
  &__title {
    font-weight: 300;
    font-size: rem-calc(18);
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
    &:before {
      content: "";
      margin-bottom: $spacing * 0.5;
      font-size: 1rem;
      opacity: 0.3;
      transition: opacity 0.15s ease-in-out;
    }
    &--dest {
      font-size: rem-calc(28);
      @media #{$medium-up} {
        font-size: rem-calc(30);
      }
      @media #{$large-up} {
        font-size: rem-calc(36);
      }
      &:before {
        @media #{$large-up} {
          font-size: 1.3rem;
        }
      }
    }
    a {
      @include hover;
      color: #fff;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }
  &__descr {
    margin-bottom: $spacing * 0.5;
    font-size: rem-calc(14);
    color: #c7c7c7;
    &--dest {
      @media #{$medium-up} {
        font-size: rem-calc(16);
      }
    }
  }
  &__meta {
    border: solid #4d4d4d;
    border-width: 1px 0;
    padding: $spacing * 0.5 0;
    font-size: rem-calc(14);
    display: flex;
    margin-bottom: 0;
    @media #{$medium-up} {
      display: block;
    }
    @media #{$large-up} {
      display: flex;
    }
    &--dest {
      @media #{$medium-up} {
        font-size: rem-calc(16);
      }
    }
    div {
      display: flex;
      // &:last-child {
      //   border-left: 1px solid #979797;
      //   padding-left: $spacing * 0.5;
      //   @media #{$medium-up} {
      //     border-left: none;
      //     padding-left: 0;
      //   }
      //   @media #{$large-up} {
      //     border-left: 1px solid #979797;
      //     padding-left: $spacing * 0.5;
      //   }
      // }
    }
    dt {
      font-weight: bold;
    }
    dd {
      font-weight: 300;
      margin-left: 0;
      padding-left: $spacing * 0.25;
      margin-right: $spacing * 0.5;
    }
  }
  &__img-wrapper {
    margin-bottom: $spacing;
    overflow: hidden;
    img {
      margin-bottom: 0;
      opacity: 1;
    }
  }
}
.fitxa {
  .galeria__item {
    &:hover .galeria__title:before {
      opacity: 0.3;
    }
    @media #{$medium-up} {
      align-items: flex-start;
    }
  }
}

