.logos-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  //   @media #{$medium-up} {
  //     margin-left: -$spacing * 0.5;
  //     margin-right: -$spacing * 0.5;
  //   }
  > li {
    margin: $spacing * 0.25;
    padding: $spacing;
    width: 100%;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
      width: calc(50% - 0.75rem);
    }
    @media screen and (min-width: 40em) {
      width: 33.33%;
      width: calc(33.33% - 0.75rem);
    }
    @media #{$large-up} {
      width: 25%;
      width: calc(25% - 0.75rem);
    }
    &:before {
      display: none;
    }
    > * + * {
      margin-top: $spacing;
    }
    a {
      text-decoration: none;
      h2 {
        font-size: .9rem;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
